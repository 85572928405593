import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "@/contexts/auth/AuthProvider";
import ProtectedRoute from "@/components/layout/ProtectedRoute";
import ProtectedUserRoute from "@/components/layout/ProtectedUserRoute";
import AdminRoute from "@/components/layout/AdminRoute";
import AdminLayout from "@/components/layout/AdminLayout";

// Public pages
import Index from "./pages/Index";
import NotFound from "./pages/NotFound";
import UnauthorizedPage from "./pages/UnauthorizedPage";
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import PublicDocumentView from "./pages/public/PublicDocumentView";
import DocumentDownload from "./pages/public/DocumentDownload";
import PublicProjectDocuments from "./pages/public/PublicProjectDocuments";

// User pages
import UserLoginPage from "./pages/UserLoginPage";
import UserPrivateSpace from "./components/UserPrivateSpace";
import OnboardingPage from "./pages/OnboardingPage";

// Admin pages
import Dashboard from "./pages/admin/Dashboard";
import ClientsPage from "./pages/admin/clients/ClientsPage";
import NewClientPage from "./pages/admin/clients/NewClientPage";
import ProjectsPage from "./pages/admin/projects/ProjectsPage";
import NewProjectPage from "./pages/admin/projects/NewProjectPage";
import EditProjectPage from "./pages/admin/projects/EditProjectPage";
import DocumentsPage from "./pages/admin/documents/DocumentsPage";
import SettingsPage from "./pages/admin/settings/SettingsPage";
import UserManagementPage from "./pages/admin/settings/UserManagementPage";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  },
});

const App = () => (
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <TooltipProvider>
        <Toaster />
        <Sonner />
        <BrowserRouter>
          <Routes>
            {/* Public routes */}
            <Route path="/" element={<Index />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/document/:shareId" element={<PublicDocumentView />} />
            <Route path="/download/:shareId" element={<DocumentDownload />} />
            <Route path="/public/documents" element={<PublicProjectDocuments />} />
            <Route path="/unauthorized" element={<UnauthorizedPage />} />

            {/* User routes */}
            <Route path="/user-login" element={<UserLoginPage />} />
            <Route path="/onboarding" element={
              <ProtectedUserRoute>
                <OnboardingPage />
              </ProtectedUserRoute>
            } />
            <Route path="/user-space" element={
              <ProtectedUserRoute>
                <UserPrivateSpace />
              </ProtectedUserRoute>
            } />

            {/* Admin routes - protected with AdminRoute */}
            <Route element={<AdminRoute />}>
              <Route element={<AdminLayout />}>
                <Route path="/admin/dashboard" element={<Dashboard />} />
                <Route path="/admin/clients" element={<ClientsPage />} />
                <Route path="/admin/clients/new" element={<NewClientPage />} />
                <Route path="/admin/projects" element={<ProjectsPage />} />
                <Route path="/admin/projects/new" element={<NewProjectPage />} />
                <Route path="/admin/projects/edit/:id" element={<EditProjectPage />} />
                <Route path="/admin/documents" element={<DocumentsPage />} />
                <Route path="/admin/settings" element={<SettingsPage />} />
                <Route path="/admin/settings/users" element={<UserManagementPage />} />
              </Route>
            </Route>

            {/* Catch-all route for 404 */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </TooltipProvider>
    </AuthProvider>
  </QueryClientProvider>
);

export default App;
