
import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Client, ClientFormData } from "@/types/client";
import { Button } from "@/components/ui/shadcn-button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

const formSchema = z.object({
  name: z.string().min(2, { message: "Le nom doit contenir au moins 2 caractères" }),
  phone: z.string().min(10, { message: "Le numéro de téléphone doit contenir au moins 10 chiffres" }),
  email: z.string().email({ message: "Veuillez entrer une adresse email valide" }).or(z.literal("")),
  preferredLanguage: z.string().default("fr"),
});

interface ClientFormProps {
  client?: Client;
  onSubmit: (data: ClientFormData) => void;
  isSubmitting: boolean;
}

const ClientForm: React.FC<ClientFormProps> = ({
  client,
  onSubmit,
  isSubmitting,
}) => {
  const form = useForm<ClientFormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: client?.name || "",
      phone: client?.phone || "",
      email: client?.email || "",
      preferredLanguage: "fr", // Default to French
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Nom</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="phone"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Téléphone (WhatsApp)</FormLabel>
              <FormControl>
                <Input {...field} placeholder="+33612345678" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input {...field} placeholder="email@example.com" type="email" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* Hidden field for preferredLanguage */}
        <input type="hidden" {...form.register("preferredLanguage")} value="fr" />

        <div className="flex justify-end gap-2">
          <Button type="button" variant="outline">
            Annuler
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Enregistrement..." : client ? "Mettre à jour" : "Créer"}
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default ClientForm;
