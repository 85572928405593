import React, { useEffect, useState } from 'react';
import api from '@/services/api';
import { getUserAuth } from '@/services/userAuthService';
import { Project } from '@/types/project';
import { Document } from '@/types/document';
import { useNavigate } from 'react-router-dom';
import WhatsAppButton from '@/components/ui/WhatsAppButton';

interface Settings {
    botWhatsappNumber: string;
}

const UserPrivateSpace = () => {
    const [projects, setProjects] = useState<Project[]>([]);
    const [selectedProject, setSelectedProject] = useState<string | null>(null);
    const [documents, setDocuments] = useState<Document[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [settings, setSettings] = useState<Settings | null>(null);
    const navigate = useNavigate();
    const userAuth = getUserAuth();

    useEffect(() => {
        // Fetch settings to get the bot WhatsApp number
        const fetchSettings = async () => {
            try {
                const response = await api.get('/api/settings');
                setSettings(response.data);
            } catch (err) {
                console.error('Error fetching settings:', err);
                // Don't set an error state here as it's not critical
            }
        };

        fetchSettings();
    }, []);

    useEffect(() => {
        // Check if user has completed onboarding
        if (userAuth && !userAuth.onboardingComplete) {
            navigate('/onboarding');
            return;
        }

        // Check if user has a clientId
        if (!userAuth?.clientId) {
            navigate('/onboarding');
            return;
        }

        const fetchProjects = async () => {
            try {
                const response = await api.get('/api/user/my-projects');
                setProjects(response.data);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching projects:', err);
                setError('Failed to load your projects. Please try again later.');
                setLoading(false);
            }
        };

        fetchProjects();
    }, [navigate, userAuth]);

    useEffect(() => {
        if (selectedProject) {
            const fetchDocuments = async () => {
                try {
                    setLoading(true);
                    const response = await api.get(`/api/user/my-documents/${selectedProject}`);
                    setDocuments(response.data);
                    setLoading(false);
                } catch (err) {
                    console.error('Error fetching documents:', err);
                    setError('Failed to load documents. Please try again later.');
                    setLoading(false);
                }
            };

            fetchDocuments();
        } else {
            setDocuments([]);
        }
    }, [selectedProject]);

    return (
        <div className="container mx-auto px-4 py-8">
            {settings?.botWhatsappNumber && (
                <div className="mb-6 p-4 bg-blue-50 rounded-lg border border-blue-200 flex items-center justify-between">
                    <div>
                        <h3 className="font-medium text-blue-800">Need help? Chat with our bot</h3>
                        <p className="text-sm text-blue-600">
                            Start a WhatsApp conversation with our assistant at: {settings.botWhatsappNumber}
                        </p>
                    </div>
                    <WhatsAppButton 
                        phoneNumber={settings.botWhatsappNumber}
                        buttonText="Chat Now"
                    />
                </div>
            )}

            <h1 className="text-2xl font-bold mb-6">Welcome, {userAuth?.clientName}</h1>

            {loading ? (
                <div>Loading...</div>
            ) : error ? (
                <div className="text-red-500">{error}</div>
            ) : (
                <>
                    <div className="mb-8">
                        <h2 className="text-xl font-semibold mb-4">Your Projects</h2>
                        {projects.length === 0 ? (
                            <p>No projects found.</p>
                        ) : (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                {projects.map(project => (
                                    <div
                                        key={project.id}
                                        className={`p-4 border rounded cursor-pointer ${selectedProject === project.id ? 'bg-blue-100 border-blue-500' : 'hover:bg-gray-50'
                                            }`}
                                        onClick={() => setSelectedProject(project.id)}
                                    >
                                        <h3 className="font-medium">{project.name}</h3>
                                        <p className="text-sm text-gray-600">{project.description}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    {selectedProject && (
                        <div>
                            <h2 className="text-xl font-semibold mb-4">Project Documents</h2>
                            {documents.length === 0 ? (
                                <p>No documents found for this project.</p>
                            ) : (
                                <div className="overflow-x-auto">
                                    <table className="w-full border-collapse">
                                        <thead>
                                            <tr className="bg-gray-100">
                                                <th className="p-2 text-left">Name</th>
                                                <th className="p-2 text-left">Type</th>
                                                <th className="p-2 text-left">Date</th>
                                                <th className="p-2 text-left">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {documents.map(doc => (
                                                <tr key={doc.id} className="border-t">
                                                    <td className="p-2">{doc.name}</td>
                                                    <td className="p-2">{doc.type}</td>
                                                    <td className="p-2">
                                                        {doc.createdAt ? new Date(doc.createdAt.seconds * 1000).toLocaleDateString() : 'N/A'}
                                                    </td>
                                                    <td className="p-2">
                                                        <a
                                                            href={doc.fileUrl}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="text-blue-500 hover:text-blue-700"
                                                        >
                                                            View
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default UserPrivateSpace; 