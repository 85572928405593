
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { File, Search, Sparkles, X } from "lucide-react";
import { getDocuments } from "@/services/documentsService";
import { Card, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Button } from "@/components/ui/shadcn-button";
import DocumentsTable from "@/components/documents/DocumentsTable";
import fr from "@/constants/fr";
import DocumentsFilter from "@/components/documents/DocumentsFilter";
import DocumentsAISearch from "@/components/documents/DocumentsAISearch";
import { Document } from "@/types/document";
import DocumentDetailView from "@/components/documents/DocumentDetailView";
import { Badge } from "@/components/ui/badge";

const PublicProjectDocuments: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDocType, setSelectedDocType] = useState<string>("");
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(null);
  const [isDetailViewOpen, setIsDetailViewOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("standard");
  const [isAiSearchLoading, setIsAiSearchLoading] = useState(false);
  const [aiFilteredDocIds, setAiFilteredDocIds] = useState<string[]>([]);
  
  // Get projectId from URL
  const projectId = searchParams.get("project");

  // Fetch documents based on project ID
  const { data: documentsData = [], isLoading: isLoadingDocuments } = useQuery({
    queryKey: ["public-documents", projectId, selectedDocType],
    queryFn: () => {
      if (!projectId) {
        return [];
      }
      const params: any = { projectId };
      if (selectedDocType && selectedDocType !== "all") {
        params.type = selectedDocType;
      }
      return getDocuments(params);
    },
    enabled: !!projectId,
  });

  // Enhanced search function to search in multiple document fields
  const searchInDocument = (document: Document, term: string): boolean => {
    if (!term) return true;
    
    const lowercaseTerm = term.toLowerCase();
    
    // Search in name
    if (document.name && document.name.toLowerCase().includes(lowercaseTerm)) {
      return true;
    }
    
    // Search in type
    if (document.type && document.type.toLowerCase().includes(lowercaseTerm)) {
      return true;
    }
    
    // Search in keywords array
    if (document.keywords && Array.isArray(document.keywords)) {
      if (document.keywords.some(keyword => 
        keyword.toLowerCase().includes(lowercaseTerm)
      )) {
        return true;
      }
    }
    
    return false;
  };

  // Filter documents based on current tab and search criteria
  const filteredDocuments = React.useMemo(() => {
    if (activeTab === "ai" && aiFilteredDocIds.length > 0) {
      return documentsData.filter(doc => doc.id && aiFilteredDocIds.includes(doc.id));
    } else {
      return documentsData.filter(document => searchInDocument(document, searchTerm));
    }
  }, [documentsData, searchTerm, activeTab, aiFilteredDocIds]);

  const handleViewDocumentDetails = (document: Document) => {
    setSelectedDocument(document);
    setIsDetailViewOpen(true);
  };

  const handleCloseDetailView = () => {
    setIsDetailViewOpen(false);
    setSelectedDocument(null);
  };

  const handleAISearchResults = (documentIds: string[]) => {
    setAiFilteredDocIds(documentIds);
  };

  const clearFilters = () => {
    setSelectedDocType("");
    setSearchTerm("");
  };

  const hasActiveFilters = selectedDocType || searchTerm;

  const renderActiveFilters = () => {
    if (!hasActiveFilters) return null;

    return (
      <div className="flex flex-wrap gap-2 mt-2">
        {selectedDocType && selectedDocType !== "all" && (
          <Badge variant="outline" className="flex items-center gap-1 bg-green-50">
            {selectedDocType}
            <X 
              className="h-3 w-3 ml-1 cursor-pointer" 
              onClick={() => setSelectedDocType("")}
            />
          </Badge>
        )}
        {searchTerm && (
          <Badge variant="outline" className="flex items-center gap-1 bg-purple-50">
            "{searchTerm}"
            <X 
              className="h-3 w-3 ml-1 cursor-pointer" 
              onClick={() => setSearchTerm("")}
            />
          </Badge>
        )}
      </div>
    );
  };

  // If no projectId is provided, show a message
  if (!projectId) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen p-4">
        <div className="text-center">
          <File className="h-16 w-16 text-gray-400 mx-auto mb-4" />
          <h2 className="text-xl font-semibold mb-2">{fr.documents.noProjectSpecified}</h2>
          <p className="text-gray-500">{fr.documents.provideProjectId}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto max-w-6xl py-8 px-4 sm:px-6">
      <div className="space-y-6">
        <div className="flex flex-col gap-2">
          <h1 className="text-2xl sm:text-3xl font-bold">{fr.documents.projectDocuments}</h1>
        </div>

        <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
          <TabsList className="grid w-full grid-cols-2 mb-4">
            <TabsTrigger value="standard">{fr.documents.standardSearch}</TabsTrigger>
            <TabsTrigger value="ai">{fr.documents.aiSearch}</TabsTrigger>
          </TabsList>
            
          <TabsContent value="standard" className="space-y-4">
            <div className="flex w-full gap-2 mb-2">
              <div className="relative flex-1">
                <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                <Input
                  type="search"
                  placeholder={fr.common.search}
                  className="pl-8 w-full"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              
              <div className="w-48">
                <DocumentsFilter 
                  selectedType={selectedDocType}
                  onSelectType={setSelectedDocType}
                />
              </div>
            </div>
            
            {renderActiveFilters()}
          </TabsContent>
              
          <TabsContent value="ai" className="space-y-4">
            <DocumentsAISearch 
              documents={documentsData}
              onSearchResults={handleAISearchResults}
              isLoading={isAiSearchLoading}
              setIsLoading={setIsAiSearchLoading}
            />
          </TabsContent>
        </Tabs>

        <Card className="shadow-sm border-0 sm:border">
          <CardContent className="p-0 sm:p-4">
            <DocumentsTable
              documents={filteredDocuments}
              isLoading={isLoadingDocuments || isAiSearchLoading}
              onViewDetails={handleViewDocumentDetails}
              isMobile={true}
            />
          </CardContent>
        </Card>

        <DocumentDetailView 
          document={selectedDocument}
          isOpen={isDetailViewOpen}
          onClose={handleCloseDetailView}
        />
      </div>
    </div>
  );
};

export default PublicProjectDocuments;
