
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FileText } from "lucide-react";
import fr from "@/constants/fr";
import api from "@/services/api";

interface DocumentData {
  id: string;
  name: string;
  type: string;
  fileUrl: string;
  category?: string;
  metadata?: Record<string, any>;
}

const PublicDocumentView: React.FC = () => {
  const { shareId } = useParams<{ shareId: string }>();
  const [document, setDocument] = useState<DocumentData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        // In a real app, this would be a specific endpoint for public document viewing
        // For now, we'll simulate it
        setLoading(true);
        
        // Simulate API call with a timeout
        setTimeout(() => {
          // Mock document data
          const mockDocument = {
            id: shareId || "doc123",
            name: "Document exemple",
            type: "FACTURE",
            fileUrl: "#",
            category: "Administratif",
            metadata: {
              date: "2023-12-01",
              montant: "15000 MAD",
              fournisseur: "Entreprise Exemple"
            }
          };
          
          setDocument(mockDocument);
          setLoading(false);
        }, 1000);
        
      } catch (error: any) {
        console.error("Error fetching document:", error);
        setError(error.userMessage || fr.errors.unknownError);
        setLoading(false);
      }
    };

    if (shareId) {
      fetchDocument();
    } else {
      setError("ID de document invalide");
      setLoading(false);
    }
  }, [shareId]);

  if (loading) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-background p-4">
        <div className="w-full max-w-2xl text-center">
          <div className="animate-pulse flex flex-col items-center">
            <div className="rounded-lg bg-secondary h-64 w-full mb-4"></div>
            <div className="h-8 bg-secondary rounded w-3/4 mb-4"></div>
            <div className="h-4 bg-secondary rounded w-1/2 mb-2"></div>
            <div className="h-4 bg-secondary rounded w-1/3"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-background p-4">
        <div className="glass-card w-full max-w-md p-8 text-center">
          <div className="text-destructive mb-4">
            <FileText className="h-16 w-16 mx-auto" />
          </div>
          <h1 className="text-xl font-bold mb-2">{fr.errors.notFound}</h1>
          <p className="text-muted-foreground">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-background p-4">
      <div className="glass-card w-full max-w-2xl p-8">
        <div className="flex items-center mb-6">
          <div className="p-3 rounded-full bg-primary/10 mr-4">
            <FileText className="h-6 w-6 text-primary" />
          </div>
          <div>
            <h1 className="text-xl font-bold">{document?.name}</h1>
            <p className="text-sm text-muted-foreground">
              Type: {document?.type} • Catégorie: {document?.category}
            </p>
          </div>
        </div>

        <div className="border border-border rounded-lg p-4 mb-6 bg-card/50">
          <div className="aspect-[16/9] bg-secondary/20 rounded flex items-center justify-center mb-4">
            <FileText className="h-16 w-16 text-muted-foreground" />
          </div>
          <div className="flex justify-between items-center">
            <span className="text-sm font-medium">{document?.name}</span>
            <button
              className="text-sm text-primary hover:text-primary/80"
              onClick={() => {
                // In a real app, this would download the file
                alert("Téléchargement simulé");
              }}
            >
              {fr.documents.download}
            </button>
          </div>
        </div>

        {document?.metadata && (
          <div>
            <h2 className="font-semibold mb-2">Métadonnées</h2>
            <div className="grid grid-cols-1 gap-2">
              {Object.entries(document.metadata).map(([key, value]) => (
                <div key={key} className="flex border-b border-border py-2 last:border-0">
                  <span className="font-medium w-1/3 text-muted-foreground capitalize">
                    {key}:
                  </span>
                  <span className="w-2/3">{value}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="mt-8 text-center text-sm text-muted-foreground">
        <p>Document partagé via {fr.common.appName}</p>
      </div>
    </div>
  );
};

export default PublicDocumentView;
