
import React from "react";
import { Link } from "react-router-dom";
import { ChevronRight } from "lucide-react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/shadcn-button";

interface Project {
  id: string;
  name: string;
  company: string;
}

interface RecentProjectsProps {
  title: string;
  projects: Project[];
}

const RecentProjects: React.FC<RecentProjectsProps> = ({ title, projects }) => {
  return (
    <Card className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">{title}</h2>
        <Button variant="ghost" size="sm" asChild>
          <Link to="/admin/projects" className="flex items-center gap-1">
            Voir tout <ChevronRight className="h-4 w-4" />
          </Link>
        </Button>
      </div>
      <div className="space-y-3">
        {projects.map((project) => (
          <Link 
            key={project.id}
            to={`/admin/projects/${project.id}`}
            className="flex items-center p-3 rounded-lg hover:bg-secondary transition-colors"
          >
            <div className="flex-1">
              <h3 className="font-medium">{project.name}</h3>
              <p className="text-sm text-muted-foreground">{project.company}</p>
            </div>
            <div className="w-2 h-2 rounded-full bg-primary"></div>
          </Link>
        ))}
      </div>
    </Card>
  );
};

export default RecentProjects;
