
import React from 'react';
import GoogleSignInButton from '@/components/GoogleSignInButton';
import { Link } from 'react-router-dom';
import { useLanguage } from '@/contexts/LanguageContext';
import LanguageSelector from '@/components/LanguageSelector';
import { ArrowLeft, Home } from 'lucide-react';

const UserLoginPage = () => {
    const { t, dir } = useLanguage();

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8" dir={dir}>
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="flex justify-between">
                    <Link 
                        to="/" 
                        className="flex items-center gap-1 text-sm text-primary hover:text-primary/80 transition-colors"
                    >
                        <Home className="h-4 w-4" />
                        <span>{t.navigation?.home || 'Home'}</span>
                    </Link>
                    <LanguageSelector variant="minimal" />
                </div>
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    {t.auth.login}
                </h2>
                <p className="mt-2 text-center text-sm text-gray-600">
                    Sign in with Google to access your project documents and files. New users will be automatically registered.
                </p>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="space-y-6">
                    <GoogleSignInButton />

                    <div className="text-sm text-center">
                        <p className="text-gray-500 mb-2">
                            If you're a new user, signing in with Google will automatically create your account.
                        </p>
                        <p className="text-gray-500">
                            This login is for clients only. For admin access,{' '}
                            <Link to="/login" className="font-semibold text-blue-600 hover:text-blue-500">
                                use the admin login
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserLoginPage;
