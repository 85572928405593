
import { createContext, useContext } from "react";
import { User } from "firebase/auth";

// Define the context type
export interface IAuthContext {
  currentUser: User | null;
  loading: boolean;
  register: (email: string, password: string, displayName: string) => Promise<{ success: boolean; error?: string }>;
  login: (email: string, password: string) => Promise<{ success: boolean; error?: string }>;
  logout: () => Promise<{ success: boolean; error?: string }>;
  resetPassword: (email: string) => Promise<{ success: boolean; error?: string }>;
  updateProfile: (displayName: string) => Promise<{ success: boolean; error?: string }>;
  updateEmail: (newEmail: string, password: string) => Promise<{ success: boolean; error?: string }>;
  updatePassword: (currentPassword: string, newPassword: string) => Promise<{ success: boolean; error?: string }>;
  setRememberMe: (rememberMe: boolean) => Promise<{ success: boolean; error?: string }>;
}

// Create the context with a default value
const AuthContext = createContext<IAuthContext>({
  currentUser: null,
  loading: true,
  register: async () => ({ success: false, error: 'Context not initialized' }),
  login: async () => ({ success: false, error: 'Context not initialized' }),
  logout: async () => ({ success: false, error: 'Context not initialized' }),
  resetPassword: async () => ({ success: false, error: 'Context not initialized' }),
  updateProfile: async () => ({ success: false, error: 'Context not initialized' }),
  updateEmail: async () => ({ success: false, error: 'Context not initialized' }),
  updatePassword: async () => ({ success: false, error: 'Context not initialized' }),
  setRememberMe: async () => ({ success: false, error: 'Context not initialized' }),
});

// Create a hook to use the auth context
export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthContext;
