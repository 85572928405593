/**
 * Formats a phone number for WhatsApp by removing all non-digit characters
 * 
 * @param phoneNumber The phone number to format
 * @returns The formatted phone number with only digits
 */
export const formatWhatsAppNumber = (phoneNumber: string): string => {
  if (!phoneNumber) return '';
  
  // Remove all non-digit characters
  return phoneNumber.replace(/\D/g, '');
};

/**
 * Ensures a phone number starts with a plus sign for display purposes
 * 
 * @param phoneNumber The phone number to format
 * @returns The formatted phone number with a leading plus sign
 */
export const formatDisplayPhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) return '';
  
  // Remove all non-digit characters
  const digitsOnly = phoneNumber.replace(/\D/g, '');
  
  // Add a plus sign if it doesn't already have one
  return `+${digitsOnly}`;
};

/**
 * Normalizes a phone number to a standard format
 * Handles different country code formats
 * 
 * @param phoneNumber The phone number to normalize
 * @returns The normalized phone number
 */
export const normalizePhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) return '';
  
  // Remove any non-digit characters
  let normalized = phoneNumber.replace(/\D/g, '');
  
  // Handle Moroccan numbers
  if (normalized.startsWith('212')) {
    return normalized;
  } else if (normalized.startsWith('0')) {
    // Convert format 0601020304 to 212601020304
    return '212' + normalized.substring(1);
  }
  
  return normalized;
};

/**
 * Validates if a phone number is in a valid format
 * 
 * @param phoneNumber The phone number to validate
 * @returns True if the phone number is valid, false otherwise
 */
export const isValidPhoneNumber = (phoneNumber: string): boolean => {
  if (!phoneNumber) return false;
  
  // Remove all non-digit characters
  const digitsOnly = phoneNumber.replace(/\D/g, '');
  
  // Check if the number has at least 10 digits (including country code)
  return digitsOnly.length >= 10;
};

/**
 * Generates all possible variants of a phone number
 * Useful for searching in databases
 * 
 * @param phoneNumber The phone number to generate variants for
 * @returns An array of phone number variants
 */
export const generatePhoneNumberVariants = (phoneNumber: string): string[] => {
  const variants = new Set<string>();
  
  // Add the original phone number
  variants.add(phoneNumber);
  
  // Add normalized version
  const normalized = normalizePhoneNumber(phoneNumber);
  variants.add(normalized);
  
  // If it starts with 212, add version without 212
  if (normalized.startsWith('212') && normalized.length > 3) {
    variants.add('0' + normalized.substring(3));
    // Add version with + sign
    variants.add('+' + normalized);
  }
  
  // If it starts with 0, add version with 212
  if (phoneNumber.startsWith('0') && phoneNumber.length > 1) {
    variants.add('212' + phoneNumber.substring(1));
    // Add version with + sign
    variants.add('+212' + phoneNumber.substring(1));
  }
  
  // If it starts with +, add version without +
  if (phoneNumber.startsWith('+')) {
    variants.add(phoneNumber.substring(1));
  }
  
  // Return unique variants as array
  return Array.from(variants);
}; 