
const fr = {
  common: {
    loading: "Chargement...",
    error: "Erreur",
    save: "Enregistrer",
    cancel: "Annuler",
    edit: "Modifier",
    delete: "Supprimer",
    search: "Rechercher",
    actions: "Actions",
    required: "Ce champ est obligatoire",
    appName: "WhatsappDocsWithAI",
    noResults: "Aucun résultat trouvé",
    filters: "Filtres",
    applyFilters: "Appliquer les filtres",
    clearFilters: "Effacer les filtres",
    returnHome: "Retour à l'accueil",
  },
  landing: {
    tagline: "WhatsApp Documents rencontre l'IA",
    description: "Gérez tous vos documents facilement. Envoyez-les via WhatsApp, laissez l'IA les organiser pour vous.",
    startNow: "Commencer maintenant",
    discoverFeatures: "Découvrir les fonctionnalités",
    features: {
      title: "Fonctionnalités principales",
      subtitle: "Découvrez comment notre solution simplifie la gestion de vos documents",
      docManagement: {
        title: "Gestion intelligente",
        description: "Organisation automatique de vos documents par catégorie, date et contenu grâce à l'IA"
      },
      security: {
        title: "Sécurité avancée",
        description: "Protection de vos documents avec contrôle d'accès et partage sécurisé"
      },
      whatsapp: {
        title: "Intégration WhatsApp",
        description: "Envoyez vos documents via WhatsApp pour un classement et un accès immédiat"
      }
    },
    cta: {
      title: "Prêt à simplifier votre gestion documentaire ?",
      description: "Rejoignez WhatsappDocsWithAI et accédez à vos documents n'importe où, n'importe quand",
      button: "Commencer maintenant"
    }
  },
  auth: {
    login: "Se connecter",
    register: "S'inscrire",
    logout: "Se déconnecter",
    email: "Adresse email",
    password: "Mot de passe",
    confirmPassword: "Confirmer le mot de passe",
    invalidEmail: "Adresse email invalide",
    passwordMismatch: "Les mots de passe ne correspondent pas",
    registerSuccess: "Inscription réussie",
    loginSuccess: "Connexion réussie",
    logoutSuccess: "Déconnexion réussie",
    loginError: "Erreur de connexion",
    registerError: "Erreur d'inscription",
    forgotPassword: "Mot de passe oublié ?",
    resetPassword: "Réinitialiser le mot de passe",
    resetPasswordSuccess: "Votre mot de passe a été réinitialisé avec succès.",
    resetPasswordError: "Erreur lors de la réinitialisation du mot de passe.",
    sendResetLink: "Envoyer le lien de réinitialisation",
    sendResetLinkSuccess: "Un email de réinitialisation a été envoyé.",
    sendResetLinkError: "Erreur lors de l'envoi de l'email de réinitialisation.",
    invalidCredentials: "Identifiants invalides",
    emailInUse: "Cette adresse email est déjà utilisée",
    weakPassword: "Le mot de passe est trop faible",
    tooManyRequests: "Trop de tentatives, veuillez réessayer plus tard",
    networkError: "Erreur réseau, veuillez vérifier votre connexion",
    requiresRecentLogin: "Cette action nécessite une connexion récente",
    genericError: "Une erreur est survenue",
  },
  clients: {
    title: "Clients",
    addClient: "Ajouter un client",
    editClient: "Modifier le client",
    deleteConfirm: "Êtes-vous sûr de vouloir supprimer ce client ?",
  },
  companies: {
    title: "Entreprises",
    addCompany: "Ajouter une entreprise",
    editCompany: "Modifier l'entreprise",
    name: "Nom",
    client: "Client",
    ice: "ICE",
    taxId: "Identifiant fiscal",
    address: "Adresse",
    phone: "Téléphone",
    email: "Email",
    companyCreated: "Entreprise créée avec succès",
    companyUpdated: "Entreprise mise à jour avec succès",
    companyDeleted: "Entreprise supprimée avec succès",
    deleteConfirm: "Êtes-vous sûr de vouloir supprimer cette entreprise ?",
  },
  projects: {
    title: "Projets",
    addProject: "Ajouter un projet",
    editProject: "Modifier le projet",
    name: "Nom",
    client: "Client",
    company: "Entreprise",
    description: "Description",
    location: "Adresse",
    status: "Statut",
    startDate: "Date de début",
    endDate: "Date de fin",
    projectUpdated: "Projet mis à jour",
    projectDeleted: "Projet supprimé",
    projectCreated: "Projet créé avec succès",
    statusActive: "Actif",
    statusCompleted: "Terminé",
    statusOnHold: "En attente",
    statusCancelled: "Annulé",
    deleteConfirm: "Êtes-vous sûr de vouloir supprimer ce projet ?",
  },
  documents: {
    title: "Documents",
    list: "Liste des documents",
    name: "Nom",
    type: "Type",
    project: "Projet",
    uploadDate: "Date d'upload",
    fileSize: "Taille",
    noDocuments: "Aucun document",
    noDocumentsDescription: "Aucun document n'a été trouvé avec les filtres actuels.",
    upload: "Téléverser",
    view: "Voir",
    download: "Télécharger",
    delete: "Supprimer",
    filterByProject: "Filtrer par projet",
    filterByType: "Filtrer par type",
    allProjects: "Tous les projets",
    allTypes: "Tous les types",
    standardSearch: "Recherche standard",
    aiSearch: "Recherche IA",
    aiSearchPlaceholder: "Demandez à l'IA de trouver des documents...",
    aiSearchHelp: "Utilisez l'IA pour trouver des documents basés sur le contenu, les mots-clés ou les métadonnées",
    aiSearchExamples: "Essayez: \"Trouvez toutes les factures pour le Projet X\" ou \"Montrez les documents sur les raccordements électriques\"",
    resultsExplanation: "Explication des résultats:",
    searchComplete: "Recherche terminée",
    searchFoundDocuments: "Trouvé {count} document(s)",
    noResultsFound: "Aucun résultat trouvé",
    tryDifferentQuery: "Essayez une autre recherche",
    details: "Détails du document",
    keywords: "Mots-clés",
    tags: "Tags",
    metadata: "Métadonnées",
    jsonContent: "Contenu JSON",
    createdOn: "Créé le",
    updatedOn: "Mis à jour le",
    category: "Catégorie",
    filters: "Filtres",
    applyFilters: "Appliquer",
    clearFilters: "Effacer tout",
    preview: "Aperçu",
    previewNotAvailable: "Aperçu non disponible",
    cannotPreview: "Ce document ne peut pas être prévisualisé",
    fullscreen: "Plein écran",
    closeFullscreen: "Fermer le plein écran",
    pdfLoading: "Chargement du PDF...",
    pdfError: "Impossible de charger le PDF",
    openInNewTab: "Ouvrir dans un nouvel onglet",
    typeInvoice: "Facture",
    typeCheck: "Chèque",
    typePurchaseOrder: "Bon de commande",
    typeBuildingPermit: "Permis de construire",
    typeArchitecturalPlan: "Plan architectural",
    typeWaterConnection: "Raccordement eau",
    typeElectricityConnection: "Raccordement électricité",
    typeUrbanAuthorization: "Autorisation urbanisme",
    typeComplianceCertificate: "Attestation conformité",
    typeQuote: "Devis",
    typeConstructionContract: "Contrat construction",
    typeOther: "Autre",
    downloadStarting: "Votre téléchargement va démarrer automatiquement",
    downloadManually: "Si votre téléchargement ne démarre pas automatiquement, cliquez sur le bouton ci-dessous",
    noProjectSpecified: "Aucun projet spécifié",
    provideProjectId: "Veuillez fournir un identifiant de projet dans l'URL",
    projectDocuments: "Documents du projet"
  },
  navigation: {
    dashboard: "Tableau de bord",
    clients: "Clients",
    companies: "Entreprises",
    projects: "Projets",
    documents: "Documents",
    settings: "Paramètres",
    whatsappTest: "Test WhatsApp",
    home: "Accueil"
  },
  dashboard: {
    title: "Tableau de bord",
    summary: "Résumé",
    welcome: "Bienvenue sur votre tableau de bord",
    totalClients: "Total des clients",
    totalCompanies: "Total des entreprises",
    totalProjects: "Total des projets",
    totalDocuments: "Total des documents",
    recentProjects: "Projets récents",
    recentDocuments: "Documents récents",
    documentsCount: "Documents",
    projectsCount: "Projets",
    companiesCount: "Entreprises",
    clientsCount: "Clients"
  },
  whatsapp: {
    title: "Test WhatsApp",
  },
  errors: {
    notFound: "Page non trouvée",
    unauthorized: "Non autorisé",
    serverError: "Erreur serveur",
    unknownError: "Une erreur inconnue est survenue",
  },
  language: {
    title: "Langue",
    fr: "Français",
    en: "Anglais",
    ar: "Arabe"
  }
};

export default fr;
