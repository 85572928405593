
import React, { useState } from 'react';
import { Button } from '@/components/ui/shadcn-button';
import { Input } from '@/components/ui/input';
import { Search, Sparkles } from 'lucide-react';
import { useToast } from '@/hooks/use-toast';
import { Document } from '@/types/document';
import fr from '@/constants/fr';
import { GoogleGenerativeAI } from "@google/generative-ai";
import { Skeleton } from '@/components/ui/skeleton';

// Initialize the Google Generative AI with API key from environment variable
const apiKey = import.meta.env.VITE_GEMINI_API_KEY || "";
const genAI = new GoogleGenerativeAI(apiKey);

interface DocumentsAISearchProps {
  documents: Document[];
  onSearchResults: (filteredDocIds: string[]) => void;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
}

/**
 * DocumentsAISearch - A component that provides AI-powered document search using Google's Gemini API
 * 
 * This component allows users to search for documents using natural language queries.
 * It uses Google's Gemini API to analyze document content and metadata to find relevant matches.
 */
const DocumentsAISearch: React.FC<DocumentsAISearchProps> = ({
  documents,
  onSearchResults,
  isLoading,
  setIsLoading,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchReasoning, setSearchReasoning] = useState<string | null>(null);
  const { toast } = useToast();

  const handleAISearch = async () => {
    if (!searchQuery.trim()) {
      toast({
        title: fr.common.error,
        description: "Please enter a search query",
        variant: "destructive",
      });
      return;
    }

    try {
      setIsLoading(true);
      setSearchReasoning(null);
      
      // Prepare document data for AI analysis
      const documentData = documents.map(doc => ({
        id: doc.id,
        name: doc.name,
        type: doc.type,
        keywords: doc.keywords || [],
        projectName: doc.projectName,
      }));

      // Initialize the Gemini model with a specific temperature for more accurate responses
      const model = genAI.getGenerativeModel({ 
        model: "gemini-2.0-flash",
        generationConfig: {
          temperature: 0.2,
          maxOutputTokens: 1000,
        }
      });

      // Create the prompt for the AI that explicitly instructs it to return structured data
      const prompt = `
        You are a smart document search assistant that helps find relevant documents based on user queries.
        Given the search query: "${searchQuery}"

        Find all documents that are relevant to this query by analyzing:
        1. Document names
        2. Document types
        3. Document keywords
        4. Project names
        
        Here are the documents to search through:
        ${JSON.stringify(documentData, null, 2)}
        
        Your response must be ONLY a valid JSON object with exactly this format:
        {
          "matchedDocuments": ["id1", "id2", ...],
          "reasoning": "Brief explanation of why these documents were selected"
        }
        
        The "matchedDocuments" must be an array of document IDs that match the search criteria.
        If no documents match, return { "matchedDocuments": [], "reasoning": "No matching documents found" }
        
        Important: Return ONLY the JSON object without any additional text, code blocks, or formatting.
      `;

      // Generate content
      const result = await model.generateContent(prompt);
      const response = result.response;
      const responseText = response.text();
      
      // Extract the JSON object from the response
      let structuredOutput;
      try {
        // First try to parse the entire text as JSON
        structuredOutput = JSON.parse(responseText);
      } catch (e) {
        // If that fails, try to extract the JSON using regex
        const jsonMatch = responseText.match(/\{[\s\S]*\}/);
        if (jsonMatch) {
          try {
            structuredOutput = JSON.parse(jsonMatch[0]);
          } catch (jsonError) {
            // If JSON is still invalid, try to sanitize it
            const sanitizedJson = jsonMatch[0]
              .replace(/(\w+):/g, '"$1":')         // Add quotes to keys
              .replace(/:\s*'([^']*)'/g, ':"$1"')  // Replace single quotes with double quotes
              .replace(/,\s*}/g, '}');             // Remove trailing commas
              
            try {
              structuredOutput = JSON.parse(sanitizedJson);
            } catch (finalError) {
              throw new Error("Failed to parse JSON from response");
            }
          }
        } else {
          throw new Error("Failed to extract JSON from response");
        }
      }
      
      // Validate that the output has the expected format
      if (!structuredOutput || !Array.isArray(structuredOutput.matchedDocuments)) {
        throw new Error("Invalid response format from AI");
      }
      
      // Update the UI with matched document IDs
      onSearchResults(structuredOutput.matchedDocuments);
      
      // Store reasoning if provided
      if (structuredOutput.reasoning) {
        setSearchReasoning(structuredOutput.reasoning);
      }
      
      // If no results found, show a message
      if (structuredOutput.matchedDocuments.length === 0) {
        toast({
          title: "No results found",
          description: "Try a different search query",
        });
      } else {
        toast({
          title: "Search complete",
          description: `Found ${structuredOutput.matchedDocuments.length} document(s)`,
        });
      }
    } catch (error) {
      toast({
        title: fr.common.error,
        description: "There was an error processing your search",
        variant: "destructive",
      });
      // Return all documents if search fails
      onSearchResults(documents.map(doc => doc.id));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-2">
        <div className="relative flex-1">
          <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            type="text"
            placeholder="Ask AI to find documents..."
            className="pl-8 w-full"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !isLoading) {
                handleAISearch();
              }
            }}
            disabled={isLoading}
          />
        </div>
        <Button 
          onClick={handleAISearch} 
          disabled={isLoading}
          size="icon"
          className="h-10 w-10"
        >
          <Sparkles className="h-4 w-4" />
        </Button>
      </div>
      
      <p className="text-xs text-muted-foreground">
        {fr.documents.aiSearchExamples}
      </p>
      
      {isLoading && (
        <div className="space-y-2 mt-4">
          <Skeleton className="h-4 w-3/4" />
          <Skeleton className="h-4 w-1/2" />
          <Skeleton className="h-4 w-5/6" />
        </div>
      )}
      
      {searchReasoning && !isLoading && (
        <div className="mt-4 p-3 bg-muted/50 rounded-md text-sm">
          <p className="font-medium mb-1 text-xs">{fr.documents.resultsExplanation}</p>
          <p className="text-xs">{searchReasoning}</p>
        </div>
      )}
    </div>
  );
};

export default DocumentsAISearch;
