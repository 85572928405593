
const ar = {
  common: {
    loading: "جاري التحميل...",
    error: "خطأ",
    save: "حفظ",
    cancel: "إلغاء",
    edit: "تعديل",
    delete: "حذف",
    search: "بحث",
    actions: "إجراءات",
    required: "هذا الحقل مطلوب",
    appName: "واتساب دوكس مع الذكاء الاصطناعي",
    noResults: "لم يتم العثور على نتائج",
    filters: "فلاتر",
    applyFilters: "تطبيق الفلاتر",
    clearFilters: "مسح الفلاتر",
    returnHome: "العودة إلى الصفحة الرئيسية",
  },
  landing: {
    tagline: "مستندات واتساب تلتقي بالذكاء الاصطناعي",
    description: "أدِر جميع مستنداتك بسهولة. أرسلها عبر واتساب، ودع الذكاء الاصطناعي ينظمها لك.",
    startNow: "ابدأ الآن",
    discoverFeatures: "اكتشف الميزات",
    features: {
      title: "الميزات الرئيسية",
      subtitle: "اكتشف كيف يبسط حلنا إدارة المستندات",
      docManagement: {
        title: "إدارة ذكية",
        description: "تنظيم تلقائي لمستنداتك حسب الفئة والتاريخ والمحتوى بفضل الذكاء الاصطناعي"
      },
      security: {
        title: "أمان متقدم",
        description: "حماية مستنداتك مع التحكم في الوصول والمشاركة الآمنة"
      },
      whatsapp: {
        title: "تكامل واتساب",
        description: "أرسل مستنداتك عبر واتساب للتصنيف والوصول الفوري"
      }
    },
    cta: {
      title: "هل أنت مستعد لتبسيط إدارة مستنداتك؟",
      description: "انضم إلى واتساب دوكس مع الذكاء الاصطناعي واصل إلى مستنداتك في أي مكان وفي أي وقت",
      button: "ابدأ الآن"
    }
  },
  auth: {
    login: "تسجيل الدخول",
    register: "إنشاء حساب",
    logout: "تسجيل الخروج",
    email: "البريد الإلكتروني",
    password: "كلمة المرور",
    confirmPassword: "تأكيد كلمة المرور",
    invalidEmail: "بريد إلكتروني غير صالح",
    passwordMismatch: "كلمات المرور غير متطابقة",
    registerSuccess: "تم إنشاء الحساب بنجاح",
    loginSuccess: "تم تسجيل الدخول بنجاح",
    logoutSuccess: "تم تسجيل الخروج بنجاح",
    loginError: "خطأ في تسجيل الدخول",
    registerError: "خطأ في إنشاء الحساب",
    forgotPassword: "نسيت كلمة المرور؟",
    resetPassword: "إعادة تعيين كلمة المرور",
    resetPasswordSuccess: "تم إعادة تعيين كلمة المرور بنجاح.",
    resetPasswordError: "خطأ في إعادة تعيين كلمة المرور.",
    sendResetLink: "إرسال رابط إعادة التعيين",
    sendResetLinkSuccess: "تم إرسال بريد إلكتروني لإعادة التعيين.",
    sendResetLinkError: "خطأ في إرسال بريد إلكتروني لإعادة التعيين.",
    invalidCredentials: "بيانات اعتماد غير صالحة",
    emailInUse: "البريد الإلكتروني مستخدم بالفعل",
    weakPassword: "كلمة المرور ضعيفة جدًا",
    tooManyRequests: "محاولات كثيرة جدًا ، يرجى المحاولة مرة أخرى لاحقًا",
    networkError: "خطأ في الشبكة ، يرجى التحقق من اتصالك",
    requiresRecentLogin: "يتطلب هذا الإجراء تسجيل دخول حديث",
    genericError: "حدث خطأ ما",
  },
  clients: {
    title: "العملاء",
    addClient: "إضافة عميل",
    editClient: "تعديل العميل",
    deleteConfirm: "هل أنت متأكد من أنك تريد حذف هذا العميل؟",
  },
  companies: {
    title: "الشركات",
    addCompany: "إضافة شركة",
    editCompany: "تعديل الشركة",
    name: "الاسم",
    client: "العميل",
    ice: "ICE",
    taxId: "الرقم الضريبي",
    address: "العنوان",
    phone: "الهاتف",
    email: "البريد الإلكتروني",
    companyCreated: "تم إنشاء الشركة بنجاح",
    companyUpdated: "تم تحديث الشركة بنجاح",
    companyDeleted: "تم حذف الشركة بنجاح",
    deleteConfirm: "هل أنت متأكد من أنك تريد حذف هذه الشركة؟",
  },
  projects: {
    title: "المشاريع",
    addProject: "إضافة مشروع",
    editProject: "تعديل المشروع",
    name: "الاسم",
    client: "العميل",
    company: "الشركة",
    description: "الوصف",
    location: "العنوان",
    status: "الحالة",
    startDate: "تاريخ البدء",
    endDate: "تاريخ الانتهاء",
    projectUpdated: "تم تحديث المشروع",
    projectDeleted: "تم حذف المشروع",
    projectCreated: "تم إنشاء المشروع بنجاح",
    statusActive: "نشط",
    statusCompleted: "مكتمل",
    statusOnHold: "معلق",
    statusCancelled: "ملغى",
    deleteConfirm: "هل أنت متأكد من أنك تريد حذف هذا المشروع؟",
  },
  documents: {
    title: "المستندات",
    list: "قائمة المستندات",
    name: "الاسم",
    type: "النوع",
    project: "المشروع",
    uploadDate: "تاريخ الرفع",
    fileSize: "الحجم",
    noDocuments: "لا توجد مستندات",
    noDocumentsDescription: "لم يتم العثور على مستندات بالفلاتر الحالية.",
    upload: "رفع",
    view: "عرض",
    download: "تنزيل",
    delete: "حذف",
    filterByProject: "تصفية حسب المشروع",
    filterByType: "تصفية حسب النوع",
    allProjects: "جميع المشاريع",
    allTypes: "جميع الأنواع",
    standardSearch: "بحث عادي",
    aiSearch: "بحث بالذكاء الاصطناعي",
    aiSearchPlaceholder: "اطلب من الذكاء الاصطناعي البحث عن المستندات...",
    aiSearchHelp: "استخدم الذكاء الاصطناعي للبحث عن المستندات بناءً على المحتوى أو الكلمات الرئيسية أو البيانات الوصفية",
    aiSearchExamples: "جرب: \"البحث عن جميع الفواتير للمشروع X\" أو \"عرض المستندات حول التوصيلات الكهربائية\"",
    resultsExplanation: "شرح النتائج:",
    searchComplete: "اكتمل البحث",
    searchFoundDocuments: "تم العثور على {count} مستند(ات)",
    noResultsFound: "لم يتم العثور على نتائج",
    tryDifferentQuery: "جرب بحثًا مختلفًا",
    details: "تفاصيل المستند",
    keywords: "الكلمات الرئيسية",
    tags: "العلامات",
    metadata: "البيانات الوصفية",
    jsonContent: "محتوى JSON",
    createdOn: "تم إنشاؤه في",
    updatedOn: "تم تحديثه في",
    category: "الفئة",
    filters: "فلاتر",
    applyFilters: "تطبيق",
    clearFilters: "مسح الكل",
    preview: "معاينة",
    previewNotAvailable: "المعاينة غير متوفرة",
    cannotPreview: "لا يمكن معاينة هذا المستند",
    fullscreen: "ملء الشاشة",
    closeFullscreen: "إغلاق ملء الشاشة",
    pdfLoading: "جاري تحميل PDF...",
    pdfError: "غير قادر على تحميل PDF",
    openInNewTab: "فتح في علامة تبويب جديدة",
    typeInvoice: "فاتورة",
    typeCheck: "شيك",
    typePurchaseOrder: "طلب شراء",
    typeBuildingPermit: "رخصة بناء",
    typeArchitecturalPlan: "مخطط معماري",
    typeWaterConnection: "توصيل المياه",
    typeElectricityConnection: "توصيل الكهرباء",
    typeUrbanAuthorization: "تصريح حضري",
    typeComplianceCertificate: "شهادة مطابقة",
    typeQuote: "عرض سعر",
    typeConstructionContract: "عقد بناء",
    typeOther: "أخرى",
    downloadStarting: "سيبدأ التنزيل تلقائيًا",
    downloadManually: "إذا لم يبدأ التنزيل تلقائيًا، انقر على الزر أدناه",
    noProjectSpecified: "لم يتم تحديد مشروع",
    provideProjectId: "يرجى تقديم معرف المشروع في عنوان URL",
    projectDocuments: "مستندات المشروع"
  },
  navigation: {
    dashboard: "لوحة التحكم",
    clients: "العملاء",
    companies: "الشركات",
    projects: "المشاريع",
    documents: "المستندات",
    settings: "الإعدادات",
    whatsappTest: "اختبار واتساب",
    home: "الرئيسية"
  },
  dashboard: {
    title: "لوحة التحكم",
    summary: "ملخص",
    welcome: "مرحبًا بك في لوحة التحكم",
    totalClients: "إجمالي العملاء",
    totalCompanies: "إجمالي الشركات",
    totalProjects: "إجمالي المشاريع",
    totalDocuments: "إجمالي المستندات",
    recentProjects: "المشاريع الأخيرة",
    recentDocuments: "المستندات الأخيرة",
    documentsCount: "المستندات",
    projectsCount: "المشاريع",
    companiesCount: "الشركات",
    clientsCount: "العملاء"
  },
  whatsapp: {
    title: "اختبار واتساب",
  },
  errors: {
    notFound: "الصفحة غير موجودة",
    unauthorized: "غير مصرح",
    serverError: "خطأ في الخادم",
    unknownError: "حدث خطأ غير معروف",
  },
  language: {
    title: "اللغة",
    fr: "الفرنسية",
    en: "الإنجليزية",
    ar: "العربية"
  }
};

export default ar;
