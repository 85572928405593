
import { 
  updateProfile, 
  updateEmail as firebaseUpdateEmail,
  updatePassword as firebaseUpdatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider
} from "firebase/auth";
import { auth } from "@/services/firebase";
import { getAuthErrorMessage } from "./errorUtils";

// Update user profile
export const updateUserProfile = async (
  displayName: string
): Promise<{ success: boolean; error?: string }> => {
  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('No user is currently logged in');
    }
    
    await updateProfile(user, { displayName });
    return { success: true };
  } catch (error: any) {
    console.error("Profile update error:", error);
    return { 
      success: false, 
      error: error.code ? getAuthErrorMessage(error.code) : error.message 
    };
  }
};

// Update user email
export const updateUserEmail = async (
  newEmail: string,
  password: string
): Promise<{ success: boolean; error?: string }> => {
  try {
    const user = auth.currentUser;
    if (!user || !user.email) {
      throw new Error('No user is currently logged in or no email available');
    }
    
    // Re-authenticate user before updating email
    const credential = EmailAuthProvider.credential(user.email, password);
    await reauthenticateWithCredential(user, credential);
    
    // Update email
    await firebaseUpdateEmail(user, newEmail);
    return { success: true };
  } catch (error: any) {
    console.error("Email update error:", error);
    return { 
      success: false, 
      error: error.code ? getAuthErrorMessage(error.code) : error.message 
    };
  }
};

// Update user password
export const updateUserPassword = async (
  currentPassword: string,
  newPassword: string
): Promise<{ success: boolean; error?: string }> => {
  try {
    const user = auth.currentUser;
    if (!user || !user.email) {
      throw new Error('No user is currently logged in or no email available');
    }
    
    // Re-authenticate user before updating password
    const credential = EmailAuthProvider.credential(user.email, currentPassword);
    await reauthenticateWithCredential(user, credential);
    
    // Update password
    await firebaseUpdatePassword(user, newPassword);
    return { success: true };
  } catch (error: any) {
    console.error("Password update error:", error);
    return { 
      success: false, 
      error: error.code ? getAuthErrorMessage(error.code) : error.message 
    };
  }
};
