
import React from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { createProject } from "@/services/projectsService";
import { ProjectFormData } from "@/types/project";
import ProjectForm from "@/components/projects/ProjectForm";
import { toast } from "@/components/ui/use-toast";
import fr from "@/constants/fr";

const NewProjectPage: React.FC = () => {
  const navigate = useNavigate();

  // Create project mutation
  const createMutation = useMutation({
    mutationFn: createProject,
    onSuccess: () => {
      toast({
        title: fr.projects.projectCreated,
      });
      navigate("/admin/projects");
    },
    onError: (error) => {
      console.error("Error creating project:", error);
      toast({
        title: fr.common.error,
        description: "Impossible de créer le projet",
        variant: "destructive",
      });
    },
  });

  const handleSubmit = (data: ProjectFormData) => {
    createMutation.mutate(data);
  };

  return (
    <div className="space-y-6">
      <h1 className="text-3xl font-bold">{fr.projects.addProject}</h1>
      <ProjectForm
        onSubmit={handleSubmit}
        isSubmitting={createMutation.isPending}
      />
    </div>
  );
};

export default NewProjectPage;
