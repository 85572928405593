
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "@/services/firebase";
import { getAuthErrorMessage } from "./errorUtils";

// Reset password
export const resetPassword = async (email: string): Promise<{ success: boolean; error?: string }> => {
  try {
    await sendPasswordResetEmail(auth, email);
    return { success: true };
  } catch (error: any) {
    console.error("Password reset error:", error);
    return { 
      success: false, 
      error: getAuthErrorMessage(error.code) 
    };
  }
};
