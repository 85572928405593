import api from './api';
import { auth, googleProvider } from '@/services/firebase';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

export interface UserAuthData {
    uid: string;
    email: string;
    onboardingComplete: boolean;
    clientId?: string;
    clientName?: string;
}

export interface OnboardingData {
    name: string;
    whatsappNumber: string; // This is stored as 'phone' field in the database for WhatsApp integration
    preferredLanguage: string;
    projectName: string;
}

export const signInWithGoogleForUser = async (): Promise<{
    success: boolean;
    userData?: UserAuthData;
    requiresOnboarding?: boolean;
    error?: string;
}> => {
    try {
        // Sign in with Google using Firebase
        const result = await signInWithPopup(auth, googleProvider);
        const token = await result.user.getIdToken();
        const email = result.user.email;

        if (!email) {
            throw new Error('No email associated with this Google account');
        }

        // Send token to backend for verification and user creation/lookup
        const response = await api.post('/api/auth/google-auth', {
            token,
            email
        });

        // Store user data in localStorage
        const userData = response.data;
        localStorage.setItem('userAuth', JSON.stringify({
            ...userData,
            isUser: true
        }));

        return {
            success: true,
            userData,
            requiresOnboarding: !userData.onboardingComplete
        };
    } catch (error: any) {
        console.error("Google Sign In error:", error);
        return {
            success: false,
            error: error.message || "Authentication failed"
        };
    }
};

export const completeUserOnboarding = async (onboardingData: OnboardingData): Promise<{
    success: boolean;
    error?: string;
}> => {
    try {
        const response = await api.post('/api/auth/complete-onboarding', onboardingData);
        
        // Update local storage to reflect completed onboarding and client info
        const currentAuth = getUserAuth();
        if (currentAuth) {
            localStorage.setItem('userAuth', JSON.stringify({
                ...currentAuth,
                onboardingComplete: true,
                clientId: response.data.clientId,
                clientName: response.data.clientName
            }));
        }
        
        return { success: true };
    } catch (error: any) {
        console.error("Onboarding completion error:", error);
        return {
            success: false,
            error: error.message || "Failed to complete onboarding"
        };
    }
};

// Get current user auth data
export const getUserAuth = (): UserAuthData | null => {
    const authData = localStorage.getItem('userAuth');
    if (authData) {
        return JSON.parse(authData);
    }
    return null;
};

// Logout user
export const logoutUser = () => {
    localStorage.removeItem('userAuth');
    // Optionally sign out from Firebase
    auth.signOut();
    // Redirect to home or login page
    window.location.href = '/';
}; 