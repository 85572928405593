import React from "react";
import { useQuery } from "@tanstack/react-query";
import fr from "@/constants/fr";
import StatisticsSection from "@/components/dashboard/StatisticsSection";
import DocumentsChart from "@/components/dashboard/DocumentsChart";
import MonthlyDocumentsChart from "@/components/dashboard/MonthlyDocumentsChart";
import RecentProjects from "@/components/dashboard/RecentProjects";
import RecentDocuments from "@/components/dashboard/RecentDocuments";
import { getDocuments } from "@/services/documentsService";
import { getProjects } from "@/services/projectsService";
import { getCompanies } from "@/services/companiesService";
import { getClients } from "@/services/clientsService";

const Dashboard: React.FC = () => {
  // Fetch real data from API
  const { data: documents = [] } = useQuery({
    queryKey: ["dashboard-documents"],
    queryFn: () => getDocuments(),
  });

  const { data: projects = [] } = useQuery({
    queryKey: ["dashboard-projects"],
    queryFn: () => getProjects(),
  });

  const { data: companies = [] } = useQuery({
    queryKey: ["dashboard-companies"],
    queryFn: () => getCompanies(),
  });

  const { data: clients = [] } = useQuery({
    queryKey: ["dashboard-clients"],
    queryFn: () => getClients(),
  });

  // Real statistics based on fetched data
  const stats = {
    documentsCount: documents.length,
    projectsCount: projects.length,
    companiesCount: companies.length,
    clientsCount: clients.length,
  };

  // Get recent projects (last 3)
  const recentProjects = projects
    .sort((a, b) => {
      // Sort by createdAt in descending order
      const dateA = a.createdAt ? new Date(a.createdAt._seconds * 1000) : new Date(0);
      const dateB = b.createdAt ? new Date(b.createdAt._seconds * 1000) : new Date(0);
      return dateB.getTime() - dateA.getTime();
    })
    .slice(0, 3)
    .map(project => ({
      id: project.id || "",
      name: project.name,
      company: companies.find(c => c.id === project.companyId)?.name || "-"
    }));

  // Get recent documents (last 4)
  const recentDocuments = documents
    .sort((a, b) => {
      // Sort by createdAt in descending order
      const dateA = a.createdAt ? new Date(a.createdAt._seconds * 1000) : new Date(0);
      const dateB = b.createdAt ? new Date(b.createdAt._seconds * 1000) : new Date(0);
      return dateB.getTime() - dateA.getTime();
    })
    .slice(0, 4)
    .map(doc => {
      const project = projects.find(p => p.id === doc.projectId);
      return {
        id: doc.id || "",
        name: doc.name,
        type: doc.type,
        project: project?.name || "-"
      };
    });

  // Calculate document type distribution - fixing the type issue here
  const documentsByType = documents.reduce((acc: Record<string, number>, doc) => {
    const type = doc.type || "unknown";
    acc[type] = (acc[type] || 0) + 1;
    return acc;
  }, {});

  // Ensuring the data has the correct type
  const documentTypeData = Object.entries(documentsByType).map(([name, value]) => ({
    name,
    value: value as number, // Explicitly cast to number to match DocumentTypeData interface
  }));

  // Calculate monthly document counts (last 5 months)
  const getMonthlyDocumentCounts = () => {
    const months = [];
    const today = new Date();
    
    for (let i = 4; i >= 0; i--) {
      const month = new Date(today.getFullYear(), today.getMonth() - i, 1);
      months.push({
        date: month,
        name: month.toLocaleDateString('fr-FR', { month: 'short' })
      });
    }
    
    const counts = months.map(month => {
      const nextMonth = new Date(month.date.getFullYear(), month.date.getMonth() + 1, 1);
      const count = documents.filter(doc => {
        if (!doc.createdAt) return false;
        const docDate = new Date(doc.createdAt._seconds * 1000);
        return docDate >= month.date && docDate < nextMonth;
      }).length;
      
      return {
        name: month.name,
        count: count
      };
    });
    
    return counts;
  };

  const monthlyDocumentsData = getMonthlyDocumentCounts();

  return (
    <div className="space-y-6 animate-fade-in">
      <div>
        <h1 className="text-3xl font-bold mb-2">{fr.dashboard.title}</h1>
        <p className="text-muted-foreground">{fr.dashboard.welcome}</p>
      </div>

      {/* Stats Cards */}
      <StatisticsSection stats={stats} />

      {/* Charts */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <DocumentsChart 
          title="Répartition des documents" 
          data={documentTypeData} 
        />
        <MonthlyDocumentsChart 
          title="Documents par mois" 
          data={monthlyDocumentsData} 
        />
      </div>

      {/* Recent Content */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <RecentProjects 
          title={fr.dashboard.recentProjects} 
          projects={recentProjects} 
        />
        <RecentDocuments 
          title={fr.dashboard.recentDocuments} 
          documents={recentDocuments} 
        />
      </div>
    </div>
  );
};

export default Dashboard;
