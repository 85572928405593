
import axios from 'axios';
import { getAuthToken } from '@/utils/auth/tokenUtils';

// Get the API URL from environment variables
const apiUrl = import.meta.env.VITE_API_URL;

// Create axios instance with the API URL
const api = axios.create({
  baseURL: apiUrl,
});

// Add authentication interceptor
api.interceptors.request.use(
  async (config) => {
    // Don't add auth header for public document endpoints
    const isPublicDocumentEndpoint = 
      config.url?.includes('/api/documents/share/') || 
      config.url?.includes('/api/documents/download/share/') ||
      config.url?.includes('/api/documents/download/') ||
      config.url?.includes('/api/download/') ||
      config.url?.includes('/api/documents') && config.url?.includes('projectId=') && !config.url?.includes('/api/projects/') ||
      config.url?.includes('/public/documents');
    
    if (!isPublicDocumentEndpoint) {
      const token = await getAuthToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
