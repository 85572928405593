
import api from "./api";
import { Project, ProjectFormData } from "@/types/project";

// Get all projects with optional client filter
export const getProjects = async (params?: { clientId?: string }) => {
  const response = await api.get("/api/projects", { params });
  return response.data;
};

// Get a single project by ID
export const getProject = async (id: string): Promise<Project> => {
  const response = await api.get(`/api/projects/${id}`);
  return response.data;
};

// Create a new project
export const createProject = async (data: ProjectFormData): Promise<Project> => {
  const response = await api.post("/api/projects", data);
  return response.data;
};

// Update an existing project
export const updateProject = async (id: string, data: ProjectFormData): Promise<Project> => {
  const response = await api.put(`/api/projects/${id}`, data);
  return response.data;
};

// Delete a project
export const deleteProject = async (id: string): Promise<void> => {
  await api.delete(`/api/projects/${id}`);
};

// Get all documents for a project
export const getProjectDocuments = async (projectId: string) => {
  const response = await api.get(`/api/projects/${projectId}/documents`);
  return response.data;
};
