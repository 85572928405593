/**
 * Formats a phone number for WhatsApp's "Click to Chat" feature
 * Removes all non-digit characters (spaces, +, -, etc.)
 * 
 * @param phoneNumber The phone number to format
 * @returns The formatted phone number with only digits
 */
export const formatWhatsAppNumber = (phoneNumber: string): string => {
  if (!phoneNumber) return '';
  
  // Remove all non-digit characters
  return phoneNumber.replace(/\D/g, '');
};

/**
 * Formats a phone number for display purposes
 * Ensures the number has a + sign at the beginning
 * 
 * @param phoneNumber The phone number to format
 * @returns The formatted phone number with a + sign
 */
export const formatDisplayPhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) return '';
  
  // Remove all non-digit characters
  const digitsOnly = phoneNumber.replace(/\D/g, '');
  
  // Add a plus sign if it doesn't already have one
  return digitsOnly.startsWith('+') ? digitsOnly : `+${digitsOnly}`;
};

/**
 * Generates a WhatsApp chat link
 * 
 * @param phoneNumber The phone number to chat with
 * @param message Optional pre-filled message
 * @returns The WhatsApp chat link
 */
export const generateWhatsAppLink = (phoneNumber: string, message?: string): string => {
  const formattedNumber = formatWhatsAppNumber(phoneNumber);
  
  if (!formattedNumber) return '';
  
  let link = `https://wa.me/${formattedNumber}`;
  
  // Add pre-filled message if provided
  if (message) {
    // Encode the message for URL
    const encodedMessage = encodeURIComponent(message);
    link += `?text=${encodedMessage}`;
  }
  
  return link;
}; 