
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
  User,
  getAdditionalUserInfo,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence
} from 'firebase/auth';

import { auth } from '@/services/firebase';
import { UserProfile } from '@/types/user';

export const registerUser = async (email: string, password: string): Promise<User> => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    console.error('Registration error:', error);
    throw error;
  }
};

export const loginUser = async (email: string, password: string): Promise<User> => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const logoutUser = async (): Promise<void> => {
  try {
    await signOut(auth);
  } catch (error) {
    console.error('Logout error:', error);
    throw error;
  }
};

export const loginWithGoogle = async (): Promise<{ user: User; isNewUser: boolean }> => {
  try {
    const provider = new GoogleAuthProvider();
    
    // Add scopes if needed
    provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
    provider.addScope('https://www.googleapis.com/auth/userinfo.email');
    
    // Optional: Set custom parameters
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    
    const userCredential = await signInWithPopup(auth, provider);
    
    // Use getAdditionalUserInfo from firebase/auth to determine if this is a new user
    const additionalInfo = getAdditionalUserInfo(userCredential);
    const isNewUser = additionalInfo?.isNewUser || false;
    
    return { 
      user: userCredential.user,
      isNewUser
    };
    
  } catch (error) {
    console.error('Google login error:', error);
    throw error;
  }
};

export const getUserProfile = (user: User): UserProfile => {
  return {
    uid: user.uid,
    email: user.email || '',
    displayName: user.displayName || '',
    photoURL: user.photoURL || '',
    emailVerified: user.emailVerified,
  };
};

// Add the persistence type function
export const setPersistenceType = async (rememberMe: boolean): Promise<{ success: boolean; error?: string }> => {
  try {
    const persistenceType = rememberMe ? browserLocalPersistence : browserSessionPersistence;
    await setPersistence(auth, persistenceType);
    return { success: true };
  } catch (error: any) {
    console.error('Set persistence error:', error);
    return { 
      success: false, 
      error: error.message 
    };
  }
};
