
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { 
  LayoutDashboard, 
  Users, 
  FolderKanban, 
  FileText, 
  Settings,
} from "lucide-react";
import fr from "@/constants/fr";
import { useAuth } from "@/contexts/auth/AuthContext";
import { Button } from "@/components/ui/shadcn-button";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  useSidebar,
} from "@/components/ui/sidebar";

const AdminSidebar: React.FC = () => {
  const location = useLocation();
  const { logout } = useAuth();
  const { setSidebarOpen } = useSidebar();

  const menuItems = [
    { title: fr.navigation.dashboard, path: "/admin/dashboard", icon: LayoutDashboard },
    { title: fr.navigation.clients, path: "/admin/clients", icon: Users },
    { title: fr.navigation.projects, path: "/admin/projects", icon: FolderKanban },
    { title: fr.navigation.documents, path: "/admin/documents", icon: FileText },
    { title: fr.navigation.settings, path: "/admin/settings", icon: Settings },
  ];

  const isActiveRoute = (path: string) => location.pathname === path;

  const handleMenuItemClick = () => {
    // Make sure the sidebar closes when a menu item is clicked
    if (window.innerWidth < 768) {
      setSidebarOpen(false);
    }
  };

  return (
    <Sidebar className="border-r border-border bg-card">
      <SidebarHeader className="p-4 flex items-center justify-between">
        <Link to="/admin/dashboard" className="text-xl font-semibold">
          {fr.common.appName}
        </Link>
      </SidebarHeader>

      <SidebarContent className="px-2 py-2">
        <div className="space-y-1">
          {menuItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              onClick={handleMenuItemClick}
              className={`flex items-center px-3 py-2 rounded-md text-sm font-medium transition-colors ${
                isActiveRoute(item.path)
                  ? "bg-primary text-primary-foreground"
                  : "text-foreground hover:bg-secondary hover:text-secondary-foreground"
              }`}
            >
              <item.icon className="h-5 w-5 mr-2" />
              <span>{item.title}</span>
            </Link>
          ))}
        </div>
      </SidebarContent>

      <SidebarFooter className="p-4">
        <Button 
          variant="outline" 
          className="w-full"
          onClick={() => logout()}
        >
          {fr.auth.logout}
        </Button>
      </SidebarFooter>
    </Sidebar>
  );
};

export default AdminSidebar;
