
import api from "./api";
import { Document, DocumentFormData } from "@/types/document";

// Get all documents with optional filters
export const getDocuments = async (params?: { 
  projectId?: string; 
  companyId?: string;
  clientId?: string;
  type?: string;
}) => {
  const response = await api.get("/api/documents", { params });
  return response.data;
};

// Get a single document by ID
export const getDocument = async (id: string): Promise<Document> => {
  const response = await api.get(`/api/documents/${id}`);
  return response.data;
};

// Get a document by share ID (public access)
export const getDocumentByShareId = async (shareId: string): Promise<Document> => {
  const response = await api.get(`/api/documents/share/${shareId}`);
  return response.data;
};

// Download a document by share ID (public access)
export const downloadDocumentByShareId = async (shareId: string): Promise<Blob> => {
  const response = await api.get(`/api/documents/download/share/${shareId}`, {
    responseType: 'blob',
  });
  return response.data;
};

// Direct redirect URL for document downloads by share ID
export const getShareDownloadUrl = (shareId: string): string => {
  return `${api.defaults.baseURL}/api/download/${shareId}`;
};

// Upload a new document
export const uploadDocument = async (projectId: string, data: FormData): Promise<Document> => {
  const response = await api.post(`/api/projects/${projectId}/documents`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

// Update document metadata
export const updateDocument = async (id: string, data: Partial<DocumentFormData>): Promise<Document> => {
  const response = await api.put(`/api/documents/${id}`, data);
  return response.data;
};

// Delete a document
export const deleteDocument = async (id: string): Promise<void> => {
  await api.delete(`/api/documents/${id}`);
};

// Download a document
export const downloadDocument = async (id: string): Promise<Blob> => {
  const response = await api.get(`/api/documents/download/${id}`, {
    responseType: 'blob',
  });
  return response.data;
};
