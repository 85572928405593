
import React from "react";
import { useNavigate } from "react-router-dom";
import { Edit, Trash2, File } from "lucide-react";
import { Project } from "@/types/project";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/shadcn-button";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Badge } from "@/components/ui/badge";
import fr from "@/constants/fr";

interface ProjectsTableProps {
  projects: Project[];
  clientNames?: Record<string, string>;
  isLoading: boolean;
  onDelete: (id: string) => void;
  onViewDocuments: (projectId: string) => void;
}

const ProjectsTable: React.FC<ProjectsTableProps> = ({
  projects,
  clientNames,
  isLoading,
  onDelete,
  onViewDocuments,
}) => {
  const navigate = useNavigate();

  if (isLoading) {
    return <div className="text-center py-4">{fr.common.loading}</div>;
  }

  if (projects.length === 0) {
    return (
      <div className="text-center py-4 text-muted-foreground">
        {fr.common.noResults}
      </div>
    );
  }

  const getStatusBadge = (status?: string) => {
    if (!status) return null;
    
    const statusMap: Record<string, { label: string; className: string }> = {
      active: { label: fr.projects.statusActive, className: "bg-green-500 hover:bg-green-600" },
      completed: { label: fr.projects.statusCompleted, className: "bg-blue-500 hover:bg-blue-600" },
      "on-hold": { label: fr.projects.statusOnHold, className: "bg-yellow-500 hover:bg-yellow-600" },
      cancelled: { label: fr.projects.statusCancelled, className: "bg-red-500 hover:bg-red-600" },
    };

    const { label, className } = statusMap[status] || { label: status, className: "" };
    
    return (
      <Badge className={className}>
        {label}
      </Badge>
    );
  };

  const formatDate = (date?: Date) => {
    if (!date) return "-";
    return new Date(date).toLocaleDateString("fr-FR");
  };

  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>{fr.projects.name}</TableHead>
            <TableHead>{fr.projects.client}</TableHead>
            <TableHead>{fr.projects.location}</TableHead>
            <TableHead>{fr.projects.status}</TableHead>
            <TableHead>{fr.projects.startDate}</TableHead>
            <TableHead className="text-right">{fr.common.actions}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {projects.map((project) => (
            <TableRow key={project.id}>
              <TableCell className="font-medium">{project.name}</TableCell>
              <TableCell>{clientNames?.[project.clientId] || "-"}</TableCell>
              <TableCell>{project.location || "-"}</TableCell>
              <TableCell>{getStatusBadge(project.status)}</TableCell>
              <TableCell>{formatDate(project.startDate)}</TableCell>
              <TableCell className="text-right">
                <div className="flex justify-end gap-2">
                  <Button
                    variant="outline"
                    size="icon"
                    onClick={() => onViewDocuments(project.id!)}
                  >
                    <File className="h-4 w-4" />
                  </Button>
                  <Button
                    variant="outline"
                    size="icon"
                    onClick={() => navigate(`/admin/projects/edit/${project.id}`)}
                  >
                    <Edit className="h-4 w-4" />
                  </Button>
                  <AlertDialog>
                    <AlertDialogTrigger asChild>
                      <Button variant="outline" size="icon" className="text-destructive">
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                      <AlertDialogHeader>
                        <AlertDialogTitle>{fr.projects.deleteConfirm}</AlertDialogTitle>
                        <AlertDialogDescription>
                          Cette action est irréversible. Êtes-vous sûr de vouloir supprimer ce projet ?
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <AlertDialogFooter>
                        <AlertDialogCancel>{fr.common.cancel}</AlertDialogCancel>
                        <AlertDialogAction
                          onClick={() => project.id && onDelete(project.id)}
                          className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
                        >
                          {fr.common.delete}
                        </AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ProjectsTable;
