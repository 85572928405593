
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getProject, updateProject } from "@/services/projectsService";
import { ProjectFormData } from "@/types/project";
import ProjectForm from "@/components/projects/ProjectForm";
import { toast } from "@/components/ui/use-toast";
import fr from "@/constants/fr";

const EditProjectPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // Fetch project data
  const { data: project, isLoading } = useQuery({
    queryKey: ["project", id],
    queryFn: () => getProject(id!),
    enabled: !!id,
  });

  // Prepare form data
  const prepareFormData = (project: any): ProjectFormData => {
    return {
      name: project.name,
      clientId: project.clientId,
      description: project.description || "",
      location: project.location || "",
      status: project.status || "active",
      startDate: project.startDate ? new Date(project.startDate).toISOString().split('T')[0] : "",
      endDate: project.endDate ? new Date(project.endDate).toISOString().split('T')[0] : "",
    };
  };

  // Update project mutation
  const updateMutation = useMutation({
    mutationFn: (data: ProjectFormData) => updateProject(id!, data),
    onSuccess: () => {
      // Invalidate projects query cache to force a refresh
      queryClient.invalidateQueries({ queryKey: ["projects"] });
      toast({
        title: fr.projects.projectUpdated,
      });
      navigate("/admin/projects");
    },
    onError: (error) => {
      console.error("Error updating project:", error);
      toast({
        title: fr.common.error,
        description: "Impossible de mettre à jour le projet",
        variant: "destructive",
      });
    },
  });

  const handleSubmit = (data: ProjectFormData) => {
    updateMutation.mutate(data);
  };

  if (isLoading) {
    return <div className="text-center py-4">{fr.common.loading}</div>;
  }

  if (!project) {
    return <div className="text-center py-4 text-destructive">{fr.common.error}</div>;
  }

  return (
    <div className="space-y-6">
      <h1 className="text-3xl font-bold">{fr.projects.editProject}</h1>
      <ProjectForm
        initialData={prepareFormData(project)}
        onSubmit={handleSubmit}
        isSubmitting={updateMutation.isPending}
      />
    </div>
  );
};

export default EditProjectPage;
