
import React, { useState, useEffect } from "react";
import { User, onAuthStateChanged } from "firebase/auth";
import { auth } from "@/services/firebase";
import AuthContext from "./AuthContext";
import { 
  registerUser as registerUserCore,
  loginUser as loginUserCore,
  logoutUser as logoutUserCore,
  resetPassword,
  updateUserProfile,
  updateUserEmail,
  updateUserPassword,
  setPersistenceType
} from "@/utils/auth";

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  // Set up auth state observer
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  // Register function
  const register = async (email: string, password: string, displayName: string) => {
    try {
      const user = await registerUserCore(email, password);
      await updateUserProfile(displayName);
      return { success: true };
    } catch (error: any) {
      return { success: false, error: error.message };
    }
  };

  // Login function
  const login = async (email: string, password: string) => {
    try {
      await loginUserCore(email, password);
      return { success: true };
    } catch (error: any) {
      return { success: false, error: error.message };
    }
  };

  // Logout function
  const logout = async () => {
    try {
      await logoutUserCore();
      return { success: true };
    } catch (error: any) {
      return { success: false, error: error.message };
    }
  };

  // Reset password function
  const resetPasswordFunc = async (email: string) => {
    return await resetPassword(email);
  };

  // Update profile function
  const updateProfileFunc = async (displayName: string) => {
    return await updateUserProfile(displayName);
  };

  // Update email function
  const updateEmailFunc = async (newEmail: string, password: string) => {
    return await updateUserEmail(newEmail, password);
  };

  // Update password function
  const updatePasswordFunc = async (currentPassword: string, newPassword: string) => {
    return await updateUserPassword(currentPassword, newPassword);
  };

  // Set remember me function
  const setRememberMe = async (rememberMe: boolean) => {
    return await setPersistenceType(rememberMe);
  };

  // Create the auth value object
  const value = {
    currentUser,
    loading,
    register,
    login,
    logout,
    resetPassword: resetPasswordFunc,
    updateProfile: updateProfileFunc,
    updateEmail: updateEmailFunc,
    updatePassword: updatePasswordFunc,
    setRememberMe,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
