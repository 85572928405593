
import React, { useState, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "@/contexts/auth/AuthContext";
import fr from "@/constants/fr";
import Button from "@/components/ui/Button";
import { AlertCircle, Home } from "lucide-react";
import { useToast } from "@/hooks/use-toast";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email || !password) {
      setError("Veuillez remplir tous les champs.");
      return;
    }

    setLoading(true);
    setError(""); // Clear any existing errors

    try {
      console.log(`Attempting to login with email: ${email}`);
      const { success, error: loginError } = await login(email, password);
      
      if (success) {
        navigate("/admin/dashboard");
      } else {
        console.error("Login form error:", loginError);
        setError(loginError || fr.auth.loginError);
        setPassword(""); // Clear password on error
      }
    } catch (error: any) {
      console.error("Unexpected error during login:", error);
      setError(error?.message || fr.auth.loginError);
      setPassword(""); // Clear password on error
    } finally {
      setLoading(false);
    }
  }, [email, password, login, navigate, toast]);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    
    // Only clear error when user makes a change to either field
    setError("");

    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  }, []);

  return (
    <div className="min-h-screen flex items-center justify-center bg-background px-4">
      <div className="glass-card w-full max-w-md p-8 space-y-6">
        <div className="flex justify-between items-center mb-4">
          <Link 
            to="/" 
            className="flex items-center gap-1 text-sm text-primary hover:text-primary/80 transition-colors"
          >
            <Home className="h-4 w-4" />
            <span>{fr.navigation?.home || 'Accueil'}</span>
          </Link>
          <h1 className="text-2xl font-bold">{fr.common.appName}</h1>
        </div>

        <div className="text-center">
          <p className="text-muted-foreground">{fr.auth.login}</p>
        </div>

        {error && (
          <div 
            className="bg-destructive/10 text-destructive p-4 rounded-md flex items-start space-x-2" 
            role="alert"
            aria-live="polite"
          >
            <AlertCircle className="h-5 w-5 mt-0.5 flex-shrink-0" />
            <div>
              <p className="font-medium">{error}</p>
              <p className="text-sm mt-1">Veuillez vérifier vos identifiants et réessayer.</p>
            </div>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4" noValidate>
          <div>
            <label htmlFor="email" className="block text-sm font-medium mb-1">
              {fr.auth.email}
            </label>
            <input
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={handleInputChange}
              className="input-primary w-full"
              required
              aria-required="true"
              aria-invalid={!!error}
              disabled={loading}
              autoComplete="email"
            />
          </div>

          <div>
            <div className="flex items-center justify-between mb-1">
              <label htmlFor="password" className="block text-sm font-medium">
                {fr.auth.password}
              </label>
              <Link
                to="/forgot-password"
                className="text-sm text-primary hover:text-primary/80"
                tabIndex={0}
              >
                {fr.auth.forgotPassword}
              </Link>
            </div>
            <input
              id="password"
              name="password"
              type="password"
              value={password}
              onChange={handleInputChange}
              className="input-primary w-full"
              required
              aria-required="true"
              aria-invalid={!!error}
              disabled={loading}
              autoComplete="current-password"
            />
          </div>

          <Button
            type="submit"
            className="w-full"
            isLoading={loading}
            disabled={loading}
          >
            {fr.auth.login}
          </Button>
        </form>

        <div className="pt-4 text-center text-sm border-t border-border">
          <p className="text-muted-foreground mb-2">
            For client access, please use the <Link to="/user-login" className="text-primary hover:text-primary/80">client login page</Link>.
          </p>
          <Link to="/" className="text-primary hover:text-primary/80">
            {fr.common.appName}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
