
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Plus, RefreshCw } from "lucide-react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getClients, deleteClient } from "@/services/clientsService";
import { Client } from "@/types/client";
import { Button } from "@/components/ui/shadcn-button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Input } from "@/components/ui/input";
import ClientsTable from "@/components/clients/ClientsTable";
import ClientForm from "@/components/clients/ClientForm";
import { toast } from "@/components/ui/use-toast";

const ClientsPage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = useState("");
  const [editingClient, setEditingClient] = useState<Client | null>(null);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  // Fetch clients
  const {
    data: clients = [],
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["clients"],
    queryFn: getClients,
  });

  // Delete client mutation
  const deleteClientMutation = useMutation({
    mutationFn: deleteClient,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["clients"] });
    },
  });

  // Filter clients based on search query
  const filteredClients = searchQuery
    ? clients.filter(
        (client) =>
          client.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          client.phone.includes(searchQuery) ||
          (client.email &&
            client.email.toLowerCase().includes(searchQuery.toLowerCase()))
      )
    : clients;

  const handleEditClient = (client: Client) => {
    setEditingClient(client);
    setIsFormModalOpen(true);
  };

  const handleCloseFormModal = () => {
    setEditingClient(null);
    setIsFormModalOpen(false);
  };

  if (isError) {
    return (
      <div className="p-4 bg-destructive/10 border border-destructive rounded-md">
        <h2 className="text-xl font-bold mb-2 text-destructive">
          Erreur de chargement
        </h2>
        <p className="text-muted-foreground mb-4">
          Impossible de charger les clients. Veuillez réessayer.
        </p>
        <Button onClick={() => refetch()}>Réessayer</Button>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">Clients</h1>
        <div className="flex gap-2">
          <Button
            onClick={() => refetch()}
            variant="outline"
            size="icon"
            aria-label="Actualiser"
          >
            <RefreshCw className="h-4 w-4" />
          </Button>
          <Button onClick={() => navigate("/admin/clients/new")}>
            <Plus className="h-4 w-4 mr-2" /> Nouveau client
          </Button>
        </div>
      </div>

      <div className="bg-card rounded-lg border shadow-sm">
        <Tabs defaultValue="all" className="w-full">
          <div className="flex flex-col sm:flex-row justify-between items-center p-4 border-b">
            <TabsList>
              <TabsTrigger value="all">Tous</TabsTrigger>
              <TabsTrigger value="active">Actifs</TabsTrigger>
              <TabsTrigger value="recent">Récents</TabsTrigger>
            </TabsList>
            <div className="w-full sm:w-auto mt-2 sm:mt-0">
              <Input
                placeholder="Rechercher..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="max-w-[300px]"
              />
            </div>
          </div>

          <TabsContent value="all" className="p-0">
            <div className="overflow-x-auto">
              <ClientsTable
                clients={filteredClients}
                onEdit={handleEditClient}
                onDelete={deleteClientMutation.mutateAsync}
                isLoading={isLoading}
              />
            </div>
          </TabsContent>

          <TabsContent value="active" className="p-0">
            <div className="overflow-x-auto">
              <ClientsTable
                clients={filteredClients}
                onEdit={handleEditClient}
                onDelete={deleteClientMutation.mutateAsync}
                isLoading={isLoading}
              />
            </div>
          </TabsContent>

          <TabsContent value="recent" className="p-0">
            <div className="overflow-x-auto">
              <ClientsTable
                clients={filteredClients.slice(0, 5)}
                onEdit={handleEditClient}
                onDelete={deleteClientMutation.mutateAsync}
                isLoading={isLoading}
              />
            </div>
          </TabsContent>
        </Tabs>
      </div>

      {/* Edit Client Modal */}
      <Dialog open={isFormModalOpen} onOpenChange={setIsFormModalOpen}>
        <DialogContent className="max-w-md">
          <DialogHeader>
            <DialogTitle>
              {editingClient ? "Modifier le client" : "Nouveau client"}
            </DialogTitle>
          </DialogHeader>
          <ClientForm
            client={editingClient || undefined}
            onSubmit={(data) => {
              toast({
                title: "Fonctionnalité en cours de développement",
                description:
                  "L'édition de client sera disponible dans une prochaine version.",
              });
              handleCloseFormModal();
            }}
            isSubmitting={false}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ClientsPage;
