import React, { useState, useEffect } from "react";
import api from "@/services/api";
import { toast } from "@/hooks/use-toast";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import Button from "@/components/ui/Button";
import { Input } from "@/components/ui/input";
import { Loader2, Search, UserCheck, UserX } from "lucide-react";

interface User {
  id: string;
  email: string;
  role: string;
  name?: string;
}

const UserManagementPage: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchEmail, setSearchEmail] = useState("");
  const [searchResults, setSearchResults] = useState<User[]>([]);
  const [processingUser, setProcessingUser] = useState<string | null>(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await api.get('/api/admin/users');
      setUsers(response.data);
      setSearchResults(response.data);
    } catch (err: any) {
      console.error('Error fetching users:', err);
      toast({
        variant: "destructive",
        title: "Failed to load users",
        description: err.response?.data?.error || "An error occurred while loading users.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchEmail(value);

    if (!value.trim()) {
      setSearchResults(users);
      return;
    }

    const filtered = users.filter(user =>
      user.email.toLowerCase().includes(value.toLowerCase())
    );
    setSearchResults(filtered);
  };

  const setUserRole = async (userId: string, role: 'admin' | 'user') => {
    try {
      setProcessingUser(userId);
      await api.put(`/api/admin/users/${userId}/role`, { role });

      // Refresh the user list instead of updating local state
      await fetchUsers();

      toast({
        title: "Role updated",
        description: `User has been set to ${role} role.`,
      });
    } catch (err: any) {
      console.error('Error updating user role:', err);
      toast({
        variant: "destructive",
        title: "Failed to update role",
        description: err.response?.data?.error || "An error occurred while updating the user role.",
      });
    } finally {
      setProcessingUser(null);
    }
  };

  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-2xl font-bold">User Management</h1>
        <p className="text-muted-foreground">
          Manage user roles and permissions.
        </p>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Users</CardTitle>
          <CardDescription>
            View and manage user roles. Admins have full access to the system.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="mb-4 relative">
            <Search className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search by email..."
              value={searchEmail}
              onChange={handleSearch}
              className="pl-10"
            />
          </div>

          {loading ? (
            <div className="flex items-center justify-center h-64">
              <Loader2 className="h-8 w-8 animate-spin text-primary" />
            </div>
          ) : searchResults.length === 0 ? (
            <div className="text-center py-8 text-muted-foreground">
              No users found.
            </div>
          ) : (
            <div className="border rounded-md">
              <table className="w-full">
                <thead>
                  <tr className="border-b bg-muted/50">
                    <th className="text-left p-3">Email</th>
                    <th className="text-left p-3">Name</th>
                    <th className="text-left p-3">Role</th>
                    <th className="text-right p-3">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {searchResults.map(user => (
                    <tr key={user.id} className="border-b last:border-b-0">
                      <td className="p-3">{user.email}</td>
                      <td className="p-3">{user.name || '-'}</td>
                      <td className="p-3">
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${user.role === 'admin'
                          ? 'bg-blue-100 text-blue-800'
                          : 'bg-gray-100 text-gray-800'
                          }`}>
                          {user.role}
                        </span>
                      </td>
                      <td className="p-3 text-right">
                        {user.role === 'admin' ? (
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => setUserRole(user.id, 'user')}
                            disabled={processingUser === user.id}
                            className="inline-flex items-center"
                          >
                            {processingUser === user.id ? (
                              <Loader2 className="mr-2 h-3 w-3 animate-spin" />
                            ) : (
                              <UserX className="mr-2 h-3 w-3" />
                            )}
                            Remove Admin
                          </Button>
                        ) : (
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => setUserRole(user.id, 'admin')}
                            disabled={processingUser === user.id}
                            className="inline-flex items-center"
                          >
                            {processingUser === user.id ? (
                              <Loader2 className="mr-2 h-3 w-3 animate-spin" />
                            ) : (
                              <UserCheck className="mr-2 h-3 w-3" />
                            )}
                            Make Admin
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default UserManagementPage; 