import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getUserAuth } from '@/services/userAuthService';

interface ProtectedUserRouteProps {
    children: React.ReactNode;
    requireOnboarding?: boolean;
}

const ProtectedUserRoute: React.FC<ProtectedUserRouteProps> = ({ children, requireOnboarding = false }) => {
    const userAuth = getUserAuth();
    const location = useLocation();
    const isOnboardingPath = location.pathname === '/onboarding';

    // If no user is logged in, redirect to login
    if (!userAuth) {
        return <Navigate to="/user-login" replace />;
    }

    // If user needs onboarding and is not on the onboarding page, redirect to onboarding
    if (!userAuth.onboardingComplete && !isOnboardingPath) {
        return <Navigate to="/onboarding" replace />;
    }

    // If user has completed onboarding and is trying to access onboarding page, redirect to user space
    if (userAuth.onboardingComplete && isOnboardingPath) {
        return <Navigate to="/user-space" replace />;
    }

    return <>{children}</>;
};

export default ProtectedUserRoute; 