
import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "@/contexts/auth/AuthContext";
import fr from "@/constants/fr";
import Button from "@/components/ui/Button";
import { AlertCircle } from "lucide-react";
import { useToast } from "@/hooks/use-toast";

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const { resetPassword } = useAuth();
  const { toast } = useToast();

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email) {
      setError("Veuillez saisir votre adresse e-mail.");
      return;
    }

    setLoading(true);
    setError(""); 
    setSuccess(false);

    try {
      const { success, error: resetError } = await resetPassword(email);
      
      if (success) {
        setSuccess(true);
        toast({
          title: "E-mail envoyé",
          description: "Veuillez vérifier votre boîte de réception pour les instructions de réinitialisation du mot de passe.",
          variant: "default",
        });
      } else {
        console.error("Reset password error:", resetError);
        setError(resetError || fr.auth.genericError);
      }
    } catch (error: any) {
      console.error("Unexpected error during password reset:", error);
      setError(error?.message || fr.auth.genericError);
    } finally {
      setLoading(false);
    }
  }, [email, resetPassword, toast]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-background px-4">
      <div className="glass-card w-full max-w-md p-8 space-y-6">
        <div className="text-center">
          <h1 className="text-2xl font-bold">{fr.common.appName}</h1>
          <p className="mt-2 text-muted-foreground">{fr.auth.resetPassword}</p>
        </div>

        {error && (
          <div 
            className="bg-destructive/10 text-destructive p-4 rounded-md flex items-start space-x-2" 
            role="alert"
          >
            <AlertCircle className="h-5 w-5 mt-0.5 flex-shrink-0" />
            <div>
              <p className="font-medium">{error}</p>
            </div>
          </div>
        )}

        {success ? (
          <div className="text-center space-y-4">
            <div className="bg-primary/10 text-primary p-4 rounded-md">
              <p className="font-medium">Instructions de réinitialisation envoyées</p>
              <p className="text-sm mt-1">Veuillez vérifier votre boîte de réception pour les instructions de réinitialisation du mot de passe.</p>
            </div>
            <Link to="/login" className="text-primary hover:text-primary/80 block">
              Retour à la connexion
            </Link>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4" noValidate>
            <div>
              <label htmlFor="email" className="block text-sm font-medium mb-1">
                {fr.auth.email}
              </label>
              <input
                id="email"
                name="email"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError("");
                }}
                className="input-primary w-full"
                required
                aria-required="true"
                aria-invalid={!!error}
                disabled={loading}
                autoComplete="email"
              />
            </div>

            <Button
              type="submit"
              className="w-full"
              isLoading={loading}
              disabled={loading}
            >
              {fr.auth.resetPassword}
            </Button>

            <div className="text-center">
              <Link
                to="/login"
                className="text-sm text-primary hover:text-primary/80"
                tabIndex={0}
              >
                Retour à la connexion
              </Link>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
