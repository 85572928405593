
import React from "react";
import { Link } from "react-router-dom";
import { ArrowRight, Sparkles } from "lucide-react";
import { motion } from "framer-motion";
import { useLanguage } from "@/contexts/LanguageContext";
import LanguageSelector from "@/components/LanguageSelector";

const Index = () => {
  const { t, dir } = useLanguage();

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6 }
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-background to-secondary/20" dir={dir}>
      {/* Header */}
      <header className="sticky top-0 z-50 backdrop-blur-md bg-background/80 border-b border-border">
        <div className="container mx-auto px-4 py-4 flex items-center justify-between">
          <div className="text-xl font-bold flex items-center gap-2">
            <Sparkles className="h-5 w-5 text-primary" />
            <span className="hidden sm:inline">{t.common.appName}</span>
          </div>
          <div className="flex gap-3 items-center">
            <LanguageSelector variant="minimal" />
            <Link to="/user-login">
              <button className="bg-blue-600 text-white px-4 py-2 rounded-full text-sm font-medium hover:bg-blue-700 transition-colors">
                {t.auth.login}
              </button>
            </Link>
          </div>
        </div>
      </header>

      {/* Hero Section */}
      <section className="container mx-auto px-4 py-16 md:py-24 flex items-center justify-center flex-1">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          className="flex flex-col items-center text-center max-w-xl"
        >
          <h1 className="text-3xl md:text-5xl font-bold tracking-tight mb-6">
            {t.landing.tagline}
          </h1>

          <p className="text-lg text-muted-foreground mb-8">
            {t.landing.description}
          </p>

          <Link to="/user-login" className="w-full max-w-xs">
            <button className="w-full bg-primary text-primary-foreground px-6 py-3 rounded-lg font-medium hover:bg-primary/90 transition-colors flex items-center justify-center gap-2">
              {t.landing.startNow}
              <ArrowRight className="h-4 w-4" />
            </button>
          </Link>
        </motion.div>
      </section>

      {/* Footer */}
      <footer className="border-t border-border py-6">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <div className="flex items-center gap-2">
              <Sparkles className="h-4 w-4 text-primary" />
              <p className="text-sm">{t.common.appName}</p>
            </div>

            <div className="flex items-center gap-4">
              <LanguageSelector />
            </div>
          </div>
          <div className="mt-4 text-center text-xs text-muted-foreground">
            <p>&copy; {new Date().getFullYear()} {t.common.appName}. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Index;
