
import api from './api';
import { Client, ClientFormData } from '@/types/client';

const BASE_URL = '/api/clients';

export const getClients = async (): Promise<Client[]> => {
  const response = await api.get(BASE_URL);
  return response.data;
};

export const getClient = async (id: string): Promise<Client> => {
  const response = await api.get(`${BASE_URL}/${id}`);
  return response.data;
};

export const createClient = async (clientData: ClientFormData): Promise<Client> => {
  const response = await api.post(BASE_URL, clientData);
  return response.data;
};

export const updateClient = async (id: string, clientData: ClientFormData): Promise<Client> => {
  const response = await api.put(`${BASE_URL}/${id}`, clientData);
  return response.data;
};

export const deleteClient = async (id: string): Promise<void> => {
  await api.delete(`${BASE_URL}/${id}`);
};

export const createTestClient = async () => {
  const response = await api.post(`${BASE_URL}/create-test-client`);
  return response.data;
};

export const createTestWhatsAppClient = async () => {
  const response = await api.post(`${BASE_URL}/create-test-whatsapp-client`);
  return response.data;
};
