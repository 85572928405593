import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { File, Search, Filter, X, Menu, ChevronDown } from "lucide-react";
import { getDocuments } from "@/services/documentsService";
import { getProjects } from "@/services/projectsService";
import { Card, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/shadcn-button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  SheetClose,
} from "@/components/ui/sheet";
import DocumentsTable from "@/components/documents/DocumentsTable";
import fr from "@/constants/fr";
import DocumentsFilter from "@/components/documents/DocumentsFilter";
import DocumentsAISearch from "@/components/documents/DocumentsAISearch";
import { Document } from "@/types/document";
import DocumentDetailView from "@/components/documents/DocumentDetailView";
import { Badge } from "@/components/ui/badge";

const DocumentsPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState<string>("");
  const [selectedDocType, setSelectedDocType] = useState<string>("");
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(null);
  const [isDetailViewOpen, setIsDetailViewOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("standard");
  const [isAiSearchLoading, setIsAiSearchLoading] = useState(false);
  const [aiFilteredDocIds, setAiFilteredDocIds] = useState<string[]>([]);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  
  // Get projectId from URL if present
  useEffect(() => {
    const projectId = searchParams.get("projectId");
    if (projectId) {
      setSelectedProjectId(projectId);
    }
  }, [searchParams]);

  // Fetch projects for filter dropdown
  const { data: projects = [] } = useQuery({
    queryKey: ["projects"],
    queryFn: () => getProjects(),
  });

  // Fetch documents based on filters
  const { data: documentsData = [], isLoading: isLoadingDocuments } = useQuery({
    queryKey: ["documents", selectedProjectId, selectedDocType],
    queryFn: () => {
      const params: any = {};
      if (selectedProjectId && selectedProjectId !== "all") {
        params.projectId = selectedProjectId;
      }
      if (selectedDocType && selectedDocType !== "all") {
        params.type = selectedDocType;
      }
      return getDocuments(params);
    },
  });

  // Enhance documents with project names
  const documents = React.useMemo(() => {
    return documentsData.map((doc: Document) => {
      // Find the project name for this document
      const project = projects.find((p) => p.id === doc.projectId);
      return {
        ...doc,
        projectName: project?.name || "-"
      };
    });
  }, [documentsData, projects]);

  // Enhanced search function to search in multiple document fields
  const searchInDocument = (document: Document, term: string): boolean => {
    if (!term) return true;
    
    const lowercaseTerm = term.toLowerCase();
    
    // Search in name
    if (document.name && document.name.toLowerCase().includes(lowercaseTerm)) {
      return true;
    }
    
    // Search in type
    if (document.type && document.type.toLowerCase().includes(lowercaseTerm)) {
      return true;
    }
    
    // Search in keywords array
    if (document.keywords && Array.isArray(document.keywords)) {
      if (document.keywords.some(keyword => 
        keyword.toLowerCase().includes(lowercaseTerm)
      )) {
        return true;
      }
    }
    
    // Search in projectName
    if (document.projectName && document.projectName.toLowerCase().includes(lowercaseTerm)) {
      return true;
    }
    
    // Search in jsonContent
    if (document.jsonContent) {
      const jsonContentStr = JSON.stringify(document.jsonContent).toLowerCase();
      if (jsonContentStr.includes(lowercaseTerm)) {
        return true;
      }
    }
    
    // Search in metadata
    if (document.metadata) {
      const metadataStr = JSON.stringify(document.metadata).toLowerCase();
      if (metadataStr.includes(lowercaseTerm)) {
        return true;
      }
    }
    
    return false;
  };

  // Filter documents based on current tab and search criteria
  const filteredDocuments = React.useMemo(() => {
    if (activeTab === "ai" && aiFilteredDocIds.length > 0) {
      return documents.filter(doc => doc.id && aiFilteredDocIds.includes(doc.id));
    } else {
      return documents.filter(document => searchInDocument(document, searchTerm));
    }
  }, [documents, searchTerm, activeTab, aiFilteredDocIds]);

  const handleViewDocumentDetails = (document: Document) => {
    setSelectedDocument(document);
    setIsDetailViewOpen(true);
  };

  const handleCloseDetailView = () => {
    setIsDetailViewOpen(false);
    setSelectedDocument(null);
  };

  const handleAISearchResults = (documentIds: string[]) => {
    setAiFilteredDocIds(documentIds);
  };

  const clearFilters = () => {
    setSelectedProjectId("");
    setSelectedDocType("");
    setSearchTerm("");
  };

  const hasActiveFilters = selectedProjectId || selectedDocType || searchTerm;

  const renderActiveFilters = () => {
    if (!hasActiveFilters) return null;

    return (
      <div className="flex flex-wrap gap-2 mt-2">
        {selectedProjectId && selectedProjectId !== "all" && (
          <Badge variant="outline" className="flex items-center gap-1 bg-blue-50">
            {projects.find(p => p.id === selectedProjectId)?.name}
            <X 
              className="h-3 w-3 ml-1 cursor-pointer" 
              onClick={() => setSelectedProjectId("")}
            />
          </Badge>
        )}
        {selectedDocType && selectedDocType !== "all" && (
          <Badge variant="outline" className="flex items-center gap-1 bg-green-50">
            {selectedDocType}
            <X 
              className="h-3 w-3 ml-1 cursor-pointer" 
              onClick={() => setSelectedDocType("")}
            />
          </Badge>
        )}
        {searchTerm && (
          <Badge variant="outline" className="flex items-center gap-1 bg-purple-50">
            "{searchTerm}"
            <X 
              className="h-3 w-3 ml-1 cursor-pointer" 
              onClick={() => setSearchTerm("")}
            />
          </Badge>
        )}
      </div>
    );
  };

  return (
    <div className="space-y-4 px-2 sm:px-4 pb-20">
      <div className="flex flex-col gap-2">
        <h1 className="text-2xl sm:text-3xl font-bold">{fr.documents.title}</h1>
      </div>

      <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
        <TabsList className="grid w-full grid-cols-2 mb-4">
          <TabsTrigger value="standard">Standard Filter</TabsTrigger>
          <TabsTrigger value="ai">AI Search</TabsTrigger>
        </TabsList>
          
        <TabsContent value="standard" className="space-y-4">
          <div className="flex w-full gap-2 mb-2">
            <div className="relative flex-1">
              <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                type="search"
                placeholder={fr.common.search}
                className="pl-8 w-full"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <Sheet>
              <SheetTrigger asChild>
                <Button variant="outline" size="icon" className="shrink-0">
                  <Filter className="h-4 w-4" />
                </Button>
              </SheetTrigger>
              <SheetContent side="bottom" className="h-[90%] sm:h-[70%] w-full rounded-t-xl">
                <SheetHeader className="text-left">
                  <SheetTitle>{fr.documents.filters}</SheetTitle>
                  <SheetDescription>
                    {fr.common.applyFilters}
                  </SheetDescription>
                </SheetHeader>
                <div className="py-6 space-y-6">
                  <div className="space-y-2">
                    <h3 className="text-sm font-medium">{fr.documents.project}</h3>
                    <Select
                      value={selectedProjectId}
                      onValueChange={setSelectedProjectId}
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder={fr.documents.filterByProject} />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="all">{fr.documents.allProjects}</SelectItem>
                        {projects.map((project) => (
                          <SelectItem key={project.id} value={project.id!}>
                            {project.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  
                  <div className="space-y-2">
                    <h3 className="text-sm font-medium">Document Type</h3>
                    <DocumentsFilter 
                      selectedType={selectedDocType}
                      onSelectType={setSelectedDocType}
                    />
                  </div>
                  
                  <div className="pt-4 flex justify-between">
                    <Button 
                      variant="outline" 
                      onClick={clearFilters}
                    >
                      {fr.documents.clearFilters}
                    </Button>
                    <SheetClose asChild>
                      <Button>{fr.documents.applyFilters}</Button>
                    </SheetClose>
                  </div>
                </div>
              </SheetContent>
            </Sheet>
          </div>
          
          {renderActiveFilters()}
        </TabsContent>
            
        <TabsContent value="ai" className="space-y-4">
          <DocumentsAISearch 
            documents={documents}
            onSearchResults={handleAISearchResults}
            isLoading={isAiSearchLoading}
            setIsLoading={setIsAiSearchLoading}
          />
        </TabsContent>
      </Tabs>

      <Card className="shadow-sm border-0 sm:border">
        <CardContent className="p-0 sm:p-4">
          <DocumentsTable
            documents={filteredDocuments}
            isLoading={isLoadingDocuments || isAiSearchLoading}
            onViewDetails={handleViewDocumentDetails}
            isMobile={true}
          />
        </CardContent>
      </Card>

      <DocumentDetailView 
        document={selectedDocument}
        isOpen={isDetailViewOpen}
        onClose={handleCloseDetailView}
      />
    </div>
  );
};

export default DocumentsPage;
