
import React from "react";
import { Card } from "@/components/ui/card";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";

interface MonthlyData {
  name: string;
  count: number;
}

interface MonthlyDocumentsChartProps {
  title: string;
  data: MonthlyData[];
}

const MonthlyDocumentsChart: React.FC<MonthlyDocumentsChartProps> = ({ title, data }) => {
  return (
    <Card className="p-4">
      <h2 className="text-xl font-bold mb-4">{title}</h2>
      <div className="h-[300px]">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="count" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
};

export default MonthlyDocumentsChart;
