import React from "react";
import { 
  FileArchive,
  FileAudio,
  FileVideo,
  FileCode,
  FileText,
  FileQuestion
} from "lucide-react";

// Import the specific icons requested by the user
import { FaFileImage, FaFilePdf, FaImage } from "react-icons/fa";
import { PiMicrosoftWordLogoFill } from "react-icons/pi";

/**
 * Custom PDF icon component that resembles the Adobe PDF logo
 */
const PdfIcon = () => (
  <div className="relative flex items-center justify-center">
    <div className="absolute w-4 h-4 bg-red-500 rounded-sm flex items-center justify-center">
      <span className="text-white font-bold text-[8px]">PDF</span>
    </div>
  </div>
);

/**
 * Custom Word document icon component that resembles Microsoft Word
 */
const WordIcon = () => (
  <div className="relative flex items-center justify-center">
    <div className="absolute w-4 h-4 bg-blue-600 rounded-sm flex items-center justify-center">
      <span className="text-white font-bold text-[8px]">W</span>
    </div>
  </div>
);

/**
 * Custom Excel spreadsheet icon component that resembles Microsoft Excel
 */
const ExcelIcon = () => (
  <div className="relative flex items-center justify-center">
    <div className="absolute w-4 h-4 bg-green-600 rounded-sm flex items-center justify-center">
      <span className="text-white font-bold text-[8px]">X</span>
    </div>
  </div>
);

/**
 * Custom Image icon component that resembles an image file
 */
const ImageFileIcon = () => (
  <div className="relative flex items-center justify-center">
    <div className="absolute w-4 h-4 bg-blue-500 rounded-sm flex items-center justify-center overflow-hidden">
      <div className="absolute bottom-0 right-0 w-2 h-2 bg-yellow-400 rounded-full -mb-0.5 -mr-0.5"></div>
      <div className="absolute top-0 left-0 w-1.5 h-1.5 bg-green-400 rounded-sm mt-0.5 ml-0.5"></div>
    </div>
  </div>
);

/**
 * Returns the appropriate icon component based on document type and file URL
 * @param type Document type string
 * @param fileUrl Optional file URL to extract extension
 * @returns React component for the icon
 */
export const getDocumentIcon = (type: string, filePath: string = ""): React.ReactNode => {
  // Check file extension first (more reliable)
  const fileExtension = filePath.split('.').pop()?.toLowerCase() || '';
  
  // PDF files
  if (fileExtension === 'pdf' || type.toLowerCase().includes('pdf')) {
    return <FaFilePdf className="h-4 w-4 text-red-600" />;
  }
  
  // Image files
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'bmp', 'svg'];
  if (imageExtensions.includes(fileExtension) || 
      type.toLowerCase().includes('image') || 
      type.toLowerCase().includes('photo')) {
    return <FaImage className="h-4 w-4 text-blue-500" />;
  }
  
  // Word documents
  const wordExtensions = ['doc', 'docx'];
  if (wordExtensions.includes(fileExtension) || 
      type.toLowerCase().includes('word') || 
      type.toLowerCase().includes('document')) {
    return <PiMicrosoftWordLogoFill className="h-4 w-4 text-blue-700" />;
  }
  
  // Excel files
  const excelExtensions = ['xls', 'xlsx', 'csv'];
  if (excelExtensions.includes(fileExtension) || 
      type.toLowerCase().includes('excel') || 
      type.toLowerCase().includes('spreadsheet')) {
    return <FileText className="h-4 w-4 text-green-600" />;
  }
  
  // Archive files
  const archiveExtensions = ['zip', 'rar', '7z', 'tar', 'gz'];
  if (archiveExtensions.includes(fileExtension) || 
      type.toLowerCase().includes('archive') || 
      type.toLowerCase().includes('zip')) {
    return <FileArchive className="h-4 w-4 text-yellow-600" />;
  }
  
  // Audio files
  const audioExtensions = ['mp3', 'wav', 'ogg', 'flac'];
  if (audioExtensions.includes(fileExtension) || 
      type.toLowerCase().includes('audio') || 
      type.toLowerCase().includes('sound')) {
    return <FileAudio className="h-4 w-4 text-purple-500" />;
  }
  
  // Video files
  const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'mkv'];
  if (videoExtensions.includes(fileExtension) || 
      type.toLowerCase().includes('video') || 
      type.toLowerCase().includes('movie')) {
    return <FileVideo className="h-4 w-4 text-pink-500" />;
  }
  
  // Code files
  const codeExtensions = ['js', 'ts', 'html', 'css', 'php', 'py', 'java', 'c', 'cpp'];
  if (codeExtensions.includes(fileExtension) || 
      type.toLowerCase().includes('code') || 
      type.toLowerCase().includes('script')) {
    return <FileCode className="h-4 w-4 text-gray-600" />;
  }
  
  // Check by document type
  if (type.toLowerCase().includes('facture') || type.toLowerCase().includes('invoice')) {
    return <FileText className="h-4 w-4 text-amber-600" />;
  }
  
  if (type.toLowerCase().includes('contrat') || type.toLowerCase().includes('contract')) {
    return <FileText className="h-4 w-4 text-indigo-600" />;
  }
  
  if (type.toLowerCase().includes('plan') || type.toLowerCase().includes('architectural')) {
    return <FileText className="h-4 w-4 text-cyan-600" />;
  }
  
  if (type.toLowerCase().includes('permis') || type.toLowerCase().includes('permit')) {
    return <FileText className="h-4 w-4 text-emerald-600" />;
  }
  
  if (type.toLowerCase().includes('cheque') || type.toLowerCase().includes('check')) {
    return <FileText className="h-4 w-4 text-violet-600" />;
  }
  
  // Default icon for unknown types
  return <FileQuestion className="h-4 w-4 text-gray-500" />;
};

/**
 * Returns the appropriate background color class for document icon based on type and file URL
 * @param type Document type string
 * @param fileUrl Optional file URL to extract extension
 * @returns CSS class string for background color
 */
export const getIconBackgroundColor = (type: string, fileUrl: string = ""): string => {
  // Check file extension first
  const fileExtension = fileUrl.split('.').pop()?.toLowerCase() || '';
  
  // PDF files
  if (fileExtension === 'pdf' || type.toLowerCase().includes('pdf')) {
    return 'bg-red-50';
  }
  
  // Image files
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'bmp', 'svg'];
  if (imageExtensions.includes(fileExtension) || 
      type.toLowerCase().includes('image') || 
      type.toLowerCase().includes('photo')) {
    return 'bg-blue-50';
  }
  
  // Word documents
  const wordExtensions = ['doc', 'docx'];
  if (wordExtensions.includes(fileExtension) || 
      type.toLowerCase().includes('word') || 
      type.toLowerCase().includes('document')) {
    return 'bg-blue-50';
  }
  
  // Excel files
  const excelExtensions = ['xls', 'xlsx', 'csv'];
  if (excelExtensions.includes(fileExtension) || 
      type.toLowerCase().includes('excel') || 
      type.toLowerCase().includes('spreadsheet')) {
    return 'bg-green-50';
  }
  
  // Archive files
  const archiveExtensions = ['zip', 'rar', '7z', 'tar', 'gz'];
  if (archiveExtensions.includes(fileExtension) || 
      type.toLowerCase().includes('archive') || 
      type.toLowerCase().includes('zip')) {
    return 'bg-yellow-50';
  }
  
  // Audio files
  const audioExtensions = ['mp3', 'wav', 'ogg', 'flac'];
  if (audioExtensions.includes(fileExtension) || 
      type.toLowerCase().includes('audio') || 
      type.toLowerCase().includes('sound')) {
    return 'bg-purple-50';
  }
  
  // Video files
  const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'mkv'];
  if (videoExtensions.includes(fileExtension) || 
      type.toLowerCase().includes('video') || 
      type.toLowerCase().includes('movie')) {
    return 'bg-pink-50';
  }
  
  // Check by document type
  if (type.toLowerCase().includes('facture') || type.toLowerCase().includes('invoice')) {
    return 'bg-amber-50';
  }
  
  if (type.toLowerCase().includes('contrat') || type.toLowerCase().includes('contract')) {
    return 'bg-indigo-50';
  }
  
  if (type.toLowerCase().includes('plan') || type.toLowerCase().includes('architectural')) {
    return 'bg-cyan-50';
  }
  
  if (type.toLowerCase().includes('permis') || type.toLowerCase().includes('permit')) {
    return 'bg-emerald-50';
  }
  
  if (type.toLowerCase().includes('cheque') || type.toLowerCase().includes('check')) {
    return 'bg-violet-50';
  }
  
  // Default background for unknown types
  return 'bg-gray-100';
};

/**
 * Checks if a file is an image based on type and URL
 * @param type Document type string
 * @param fileUrl File URL to check
 * @returns Boolean indicating if the file is an image
 */
export const isImageFile = (type: string, fileUrl: string = ""): boolean => {
  if (!fileUrl) return false;
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp', '.bmp', '.svg'];
  return imageExtensions.some(ext => fileUrl.toLowerCase().includes(ext)) || 
         type.toLowerCase().includes('image') ||
         type.toLowerCase().includes('photo');
};

/**
 * Checks if a file is a PDF based on type and URL
 * @param type Document type string
 * @param fileUrl File URL to check
 * @returns Boolean indicating if the file is a PDF
 */
export const isPdfFile = (type: string, fileUrl: string = ""): boolean => {
  if (!fileUrl) return false;
  return fileUrl.toLowerCase().includes('.pdf') || 
         type.toLowerCase().includes('pdf');
}; 