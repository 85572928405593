import React, { useState, useEffect } from "react";
import { X, FileText, Download, ExternalLink, ArrowLeft, Maximize2, ChevronLeft, ChevronRight, Loader2 } from "lucide-react";
import { Document } from "@/types/document";
import { 
  Sheet, 
  SheetContent, 
  SheetHeader, 
  SheetTitle, 
  SheetClose 
} from "@/components/ui/sheet";
import { Button } from "@/components/ui/shadcn-button";
import { Badge } from "@/components/ui/badge";
import { Separator } from "@/components/ui/separator";
import fr from "@/constants/fr";
import { getDocumentIcon, getIconBackgroundColor, isImageFile, isPdfFile } from "@/utils/documentIcons";

interface DocumentDetailViewProps {
  document: Document | null;
  isOpen: boolean;
  onClose: () => void;
}

const DocumentDetailView: React.FC<DocumentDetailViewProps> = ({ 
  document, 
  isOpen, 
  onClose 
}) => {
  const [isFullScreenPreview, setIsFullScreenPreview] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(true);
  const [pdfError, setPdfError] = useState(false);
  
  // Check for mobile screen size
  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 640);
    };
    
    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);
    
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);
  
  // Reset PDF loading state when document changes
  useEffect(() => {
    if (document && isPdfFile(document.type, document.fileUrl)) {
      setPdfLoading(true);
      setPdfError(false);
    }
  }, [document]);
  
  if (!document) return null;

  const formatDate = (dateValue: any) => {
    if (!dateValue) return "-";
    
    try {
      // Handle Firebase Timestamp objects which might have _seconds and _nanoseconds
      if (typeof dateValue === 'object' && dateValue !== null && '_seconds' in dateValue) {
        // Convert Firebase Timestamp to milliseconds and create a Date
        const milliseconds = dateValue._seconds * 1000;
        const date = new Date(milliseconds);
        if (isNaN(date.getTime())) {
          return "-";
        }
        return new Intl.DateTimeFormat("fr-FR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }).format(date);
      }
      
      // Handle standard date strings
      const date = new Date(dateValue);
      if (isNaN(date.getTime())) {
        return "-";
      }
      
      return new Intl.DateTimeFormat("fr-FR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      }).format(date);
    } catch (error) {
      return "-";
    }
  };

  const renderMetadata = (data: Record<string, any> | undefined) => {
    if (!data || Object.keys(data).length === 0) return "-";
    
    return (
      <div className="bg-muted p-3 rounded-md overflow-auto max-h-48 text-sm">
        <pre className="whitespace-pre-wrap">{JSON.stringify(data, null, 2)}</pre>
      </div>
    );
  };

  const renderTags = (tags: string[] | undefined) => {
    if (!tags || tags.length === 0) return "-";

    return (
      <div className="flex flex-wrap gap-2">
        {tags.map((tag, index) => (
          <Badge key={index} variant="outline">{tag}</Badge>
        ))}
      </div>
    );
  };
  
  const handlePdfLoad = () => {
    setPdfLoading(false);
  };
  
  const handlePdfError = () => {
    setPdfLoading(false);
    setPdfError(true);
  };
  
  const renderPdfPreview = (url: string, height: string = "200px") => {
    // Add a timestamp to the URL to prevent caching issues
    const pdfUrl = `${url}#toolbar=0&view=FitH&t=${new Date().getTime()}`;
    
    return (
      <div className="relative bg-white w-full h-full">
        {pdfLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-muted/20">
            <div className="flex flex-col items-center">
              <Loader2 className="h-8 w-8 animate-spin text-primary" />
              <p className="mt-2 text-sm text-muted-foreground">{fr.documents.pdfLoading}</p>
            </div>
          </div>
        )}
        
        {pdfError && (
          <div className="absolute inset-0 flex items-center justify-center bg-muted/20">
            <div className="flex flex-col items-center">
              <FileText className="h-8 w-8 text-destructive" />
              <p className="mt-2 text-sm text-muted-foreground">{fr.documents.pdfError}</p>
              <Button 
                variant="outline" 
                size="sm" 
                className="mt-2"
                asChild
              >
                <a href={url} target="_blank" rel="noopener noreferrer">
                  <ExternalLink className="h-4 w-4 mr-2" />
                  {fr.documents.openInNewTab}
                </a>
              </Button>
            </div>
          </div>
        )}
        
        <iframe 
          src={pdfUrl}
          title={document.name}
          className={`w-full h-[${height}]`}
          onLoad={handlePdfLoad}
          onError={handlePdfError}
          style={{ height }}
        />
      </div>
    );
  };
  
  const renderDocumentPreview = () => {
    if (!document.fileUrl) return null;
    
    if (isFullScreenPreview) {
      return (
        <div className="fixed inset-0 z-50 bg-black flex items-center justify-center">
          <div className="absolute top-4 right-4 flex gap-2">
            <Button variant="ghost" size="icon" onClick={() => setIsFullScreenPreview(false)} className="bg-black/20 text-white hover:bg-black/40">
              <X className="h-5 w-5" />
            </Button>
          </div>
          
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-2">
            <Button asChild variant="outline" className="bg-black/20 text-white hover:bg-black/40">
              <a href={document.fileUrl} download>
                <Download className="h-4 w-4 mr-2" />
                {fr.documents.download}
              </a>
            </Button>
          </div>
          
          {isImageFile(document.type, document.fileUrl) ? (
            <img 
              src={document.fileUrl} 
              alt={document.name} 
              className="max-h-[90vh] max-w-[90vw] object-contain"
            />
          ) : isPdfFile(document.type, document.fileUrl) ? (
            <div className="w-full h-[90vh] max-w-4xl mx-auto">
              {renderPdfPreview(document.fileUrl, "90vh")}
            </div>
          ) : (
            <div className="text-white text-center p-8">
              <FileText className="h-16 w-16 mx-auto mb-4" />
              <p>{fr.documents.cannotPreview}</p>
              <Button asChild variant="outline" className="mt-4">
                <a href={document.fileUrl} target="_blank" rel="noopener noreferrer">
                  <ExternalLink className="h-4 w-4 mr-2" />
                  {fr.documents.view}
                </a>
              </Button>
            </div>
          )}
        </div>
      );
    }
    
    return (
      <div className="mb-4 relative">
        <div className="rounded-lg overflow-hidden border bg-muted/20 relative">
          {isImageFile(document.type, document.fileUrl) ? (
            <div className="relative">
              <img 
                src={document.fileUrl} 
                alt={document.name} 
                className="w-full object-contain max-h-[200px] mx-auto"
              />
              <Button 
                variant="ghost" 
                size="icon" 
                className="absolute bottom-2 right-2 bg-black/20 text-white hover:bg-black/40 rounded-full"
                onClick={() => setIsFullScreenPreview(true)}
                aria-label={fr.documents.fullscreen}
              >
                <Maximize2 className="h-4 w-4" />
              </Button>
            </div>
          ) : isPdfFile(document.type, document.fileUrl) ? (
            <div className="relative h-[200px]">
              {renderPdfPreview(document.fileUrl)}
              <Button 
                variant="ghost" 
                size="icon" 
                className="absolute bottom-2 right-2 bg-black/20 text-white hover:bg-black/40 rounded-full z-10"
                onClick={() => setIsFullScreenPreview(true)}
                aria-label={fr.documents.fullscreen}
              >
                <Maximize2 className="h-4 w-4" />
              </Button>
            </div>
          ) : (
            <div className="py-8 text-center">
              <FileText className="h-12 w-12 mx-auto mb-2 text-muted-foreground" />
              <p className="text-sm text-muted-foreground">{fr.documents.previewNotAvailable}</p>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <Sheet open={isOpen} onOpenChange={(open) => !open && onClose()}>
        <SheetContent 
          side={isMobile ? "bottom" : "right"}
          className={`
            ${isMobile ? "h-[90vh] rounded-t-xl pt-6" : "h-full rounded-none pt-12"} 
            sm:max-w-md w-full overflow-y-auto
          `}
        >
          <div className="flex items-center justify-between mb-2 sm:hidden">
            <Button variant="ghost" size="icon" onClick={onClose}>
              <ArrowLeft className="h-5 w-5" />
            </Button>
            <h2 className="text-lg font-semibold text-center flex-1">{fr.documents.details}</h2>
            <div className="w-8" />
          </div>
          
          <SheetHeader className="mb-4 hidden sm:flex">
            <SheetTitle className="flex items-center gap-2 overflow-hidden">
              <span className="shrink-0">
                {getDocumentIcon(document.type, document.filePath)}
              </span>
              <span className="truncate">{document.name}</span>
            </SheetTitle>
            <SheetClose />
          </SheetHeader>
          
          <div className="visible sm:hidden mb-2">
            <div className="flex items-center gap-3">
              <div className={`flex h-12 w-12 items-center justify-center rounded-full ${getIconBackgroundColor(document.type, document.filePath)}`}>
                {getDocumentIcon(document.type, document.filePath)}
              </div>
              <div className="flex-1 min-w-0 overflow-hidden">
                <h2 className="text-xl font-semibold truncate">{document.name}</h2>
                <Badge variant="outline" className="mt-1">
                  {document.type}
                </Badge>
              </div>
            </div>
          </div>
          
          {/* Document Preview Section */}
          {renderDocumentPreview()}
          
          <div className="flex justify-between gap-2 mb-6">
            <Button size="sm" variant="outline" className="flex-1 justify-center" asChild>
              <a href={document.fileUrl} target="_blank" rel="noopener noreferrer" className="flex items-center gap-1">
                <ExternalLink className="h-4 w-4" />
                {fr.documents.view}
              </a>
            </Button>
            <Button size="sm" variant="outline" className="flex-1 justify-center" asChild>
              <a href={document.fileUrl} download className="flex items-center gap-1">
                <Download className="h-4 w-4" />
                {fr.documents.download}
              </a>
            </Button>
          </div>
          
          <div className="space-y-6">
            <div className="grid grid-cols-1 gap-4">            
              <div>
                <h3 className="text-sm font-medium text-muted-foreground mb-1">
                  {fr.documents.project}
                </h3>
                <p>{document.projectName || "-"}</p>
              </div>

              <Separator />
              
              {document.category && (
                <div>
                  <h3 className="text-sm font-medium text-muted-foreground mb-1">
                    {fr.documents.category}
                  </h3>
                  <p>{document.category}</p>
                </div>
              )}
              
              <div>
                <h3 className="text-sm font-medium text-muted-foreground mb-1">
                  {fr.documents.tags}
                </h3>
                {renderTags(document.tags)}
              </div>
              
              <div>
                <h3 className="text-sm font-medium text-muted-foreground mb-1">
                  {fr.documents.keywords}
                </h3>
                {renderTags(document.keywords)}
              </div>
              
              <Separator />
              
              <div>
                <h3 className="text-sm font-medium text-muted-foreground mb-1">
                  {fr.documents.metadata}
                </h3>
                {renderMetadata(document.metadata)}
              </div>
              
              {document.jsonContent && (
                <div>
                  <h3 className="text-sm font-medium text-muted-foreground mb-1">
                    {fr.documents.jsonContent}
                  </h3>
                  {renderMetadata(document.jsonContent)}
                </div>
              )}
              
              <Separator />
              
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h3 className="text-sm font-medium text-muted-foreground mb-1">
                    {fr.documents.createdOn}
                  </h3>
                  <p>{formatDate(document.createdAt)}</p>
                </div>
                <div>
                  <h3 className="text-sm font-medium text-muted-foreground mb-1">
                    {fr.documents.updatedOn}
                  </h3>
                  <p>{formatDate(document.updatedAt)}</p>
                </div>
              </div>
            </div>
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
};

export default DocumentDetailView;
