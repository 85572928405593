
import fr from "@/constants/fr";

// Error message mapper
export const getAuthErrorMessage = (errorCode: string): string => {
  console.log("Error code:", errorCode);
  
  switch (errorCode) {
    case 'auth/invalid-credential':
    case 'auth/user-not-found':
    case 'auth/wrong-password':
      return fr.auth.invalidCredentials;
    case 'auth/email-already-in-use':
      return fr.auth.emailInUse;
    case 'auth/weak-password':
      return fr.auth.weakPassword;
    case 'auth/invalid-email':
      return fr.auth.invalidEmail;
    case 'auth/too-many-requests':
      return fr.auth.tooManyRequests;
    case 'auth/network-request-failed':
      return fr.auth.networkError;
    case 'auth/requires-recent-login':
      return fr.auth.requiresRecentLogin;
    default:
      return fr.auth.genericError;
  }
};
