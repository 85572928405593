
import React from "react";
import { FileText, Users, Building2, FolderKanban } from "lucide-react";
import StatCard from "./StatCard";
import fr from "@/constants/fr";

interface StatsData {
  documentsCount: number;
  projectsCount: number;
  companiesCount: number;
  clientsCount: number;
}

interface StatisticsSectionProps {
  stats: StatsData;
}

const StatisticsSection: React.FC<StatisticsSectionProps> = ({ stats }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      <StatCard 
        icon={FileText} 
        label={fr.dashboard.documentsCount} 
        value={stats.documentsCount} 
      />
      <StatCard 
        icon={FolderKanban} 
        label={fr.dashboard.projectsCount} 
        value={stats.projectsCount} 
      />
      <StatCard 
        icon={Building2} 
        label={fr.dashboard.companiesCount} 
        value={stats.companiesCount} 
      />
      <StatCard 
        icon={Users} 
        label={fr.dashboard.clientsCount} 
        value={stats.clientsCount} 
      />
    </div>
  );
};

export default StatisticsSection;
