import React from 'react';
import { Link } from 'react-router-dom';
import { Shield, ArrowLeft } from 'lucide-react';

const UnauthorizedPage = () => {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-gray-50 px-6 py-12">
      <div className="mx-auto max-w-md text-center">
        <div className="mb-6 flex justify-center">
          <Shield className="h-24 w-24 text-red-500" />
        </div>
        <h1 className="mb-4 text-3xl font-bold text-gray-900">Access Denied</h1>
        <p className="mb-8 text-lg text-gray-600">
          You don't have permission to access this page. This area is restricted to administrators only.
        </p>
        <div className="flex flex-col space-y-4">
          <Link
            to="/"
            className="inline-flex items-center justify-center rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            <ArrowLeft className="mr-2 h-4 w-4" />
            Return to Home
          </Link>
          <p className="text-sm text-gray-500">
            If you believe you should have access to this page, please contact your administrator.
          </p>
        </div>
      </div>
    </div>
  );
};

export default UnauthorizedPage; 