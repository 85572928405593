import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { completeUserOnboarding, OnboardingData } from '@/services/userAuthService';
import { toast } from '@/hooks/use-toast';
import { formatWhatsAppNumber, isValidPhoneNumber } from '@/utils/phone';

const OnboardingPage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState<OnboardingData>({
        name: '',
        whatsappNumber: '',
        preferredLanguage: 'en', // Default language code
        projectName: ''
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);

        try {
            // Format the WhatsApp number before submitting
            const formattedData = {
                ...formData,
                whatsappNumber: formatWhatsAppNumber(formData.whatsappNumber)
            };

            // Validate the phone number
            if (!isValidPhoneNumber(formattedData.whatsappNumber)) {
                toast({
                    variant: "destructive",
                    title: "Invalid phone number",
                    description: "Please enter a valid WhatsApp number with country code",
                });
                setLoading(false);
                return;
            }

            const result = await completeUserOnboarding(formattedData);
            
            if (result.success) {
                toast({
                    title: "Onboarding complete",
                    description: "Your account has been set up successfully.",
                });
                navigate('/user-space');
            } else {
                toast({
                    variant: "destructive",
                    title: "Onboarding failed",
                    description: result.error || "Failed to complete onboarding",
                });
            }
        } catch (error) {
            console.error('Error during onboarding:', error);
            toast({
                variant: "destructive",
                title: "Onboarding failed",
                description: "An unexpected error occurred",
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Complete Your Account Setup
                </h2>
                <p className="mt-2 text-center text-sm text-gray-600">
                    Please provide the following information to set up your account.
                </p>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                            Your Name
                        </label>
                        <div className="mt-2">
                            <input
                                id="name"
                                name="name"
                                type="text"
                                required
                                value={formData.name}
                                onChange={handleChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 px-2"
                            />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="whatsappNumber" className="block text-sm font-medium leading-6 text-gray-900">
                            WhatsApp Number
                        </label>
                        <div className="mt-2">
                            <input
                                id="whatsappNumber"
                                name="whatsappNumber"
                                type="tel"
                                required
                                placeholder="e.g. 212661421879 (no spaces or special characters)"
                                value={formData.whatsappNumber}
                                onChange={handleChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 px-2"
                            />
                            <p className="mt-1 text-sm text-gray-500">
                                Enter your full WhatsApp number with country code (e.g., 212XXXXXXXXX for Morocco). 
                                Do not include spaces, dashes, or the '+' symbol. This number will be used to identify you when you message the WhatsApp bot.
                            </p>
                        </div>
                    </div>

                    <div>
                        <label htmlFor="preferredLanguage" className="block text-sm font-medium leading-6 text-gray-900">
                            Preferred Language
                        </label>
                        <div className="mt-2">
                            <select
                                id="preferredLanguage"
                                name="preferredLanguage"
                                required
                                value={formData.preferredLanguage}
                                onChange={handleChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 px-2"
                            >
                                <option value="en">English</option>
                                <option value="fr">French</option>
                                <option value="es">Spanish</option>
                                <option value="ar">Arabic</option>
                                <option value="other">Other</option>
                            </select>
                        </div>
                    </div>

                    <div>
                        <label htmlFor="projectName" className="block text-sm font-medium leading-6 text-gray-900">
                            Project Name
                        </label>
                        <div className="mt-2">
                            <input
                                id="projectName"
                                name="projectName"
                                type="text"
                                required
                                value={formData.projectName}
                                onChange={handleChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 px-2"
                            />
                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            disabled={loading}
                            className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                        >
                            {loading ? 'Processing...' : 'Complete Setup'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default OnboardingPage; 