
import React from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import fr from "@/constants/fr";

interface DocumentsFilterProps {
  selectedType: string;
  onSelectType: (type: string) => void;
}

const DocumentsFilter: React.FC<DocumentsFilterProps> = ({
  selectedType,
  onSelectType,
}) => {
  return (
    <Select value={selectedType} onValueChange={onSelectType}>
      <SelectTrigger>
        <SelectValue placeholder={fr.documents.filterByType} />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="all">{fr.documents.allTypes}</SelectItem>
        <SelectItem value="facture">{fr.documents.typeInvoice}</SelectItem>
        <SelectItem value="cheque">{fr.documents.typeCheck}</SelectItem>
        <SelectItem value="bon_de_commande">{fr.documents.typePurchaseOrder}</SelectItem>
        <SelectItem value="permis_de_construire">{fr.documents.typeBuildingPermit}</SelectItem>
        <SelectItem value="plan_architectural">{fr.documents.typeArchitecturalPlan}</SelectItem>
        <SelectItem value="raccordement_eau">{fr.documents.typeWaterConnection}</SelectItem>
        <SelectItem value="raccordement_electricite">{fr.documents.typeElectricityConnection}</SelectItem>
        <SelectItem value="autorisation_urbanisme">{fr.documents.typeUrbanAuthorization}</SelectItem>
        <SelectItem value="attestation_conformite">{fr.documents.typeComplianceCertificate}</SelectItem>
        <SelectItem value="devis">{fr.documents.typeQuote}</SelectItem>
        <SelectItem value="contrat_construction">{fr.documents.typeConstructionContract}</SelectItem>
        <SelectItem value="autre">{fr.documents.typeOther}</SelectItem>
      </SelectContent>
    </Select>
  );
};

export default DocumentsFilter;
