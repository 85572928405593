import React from "react";
import { useNavigate } from "react-router-dom";
import { 
  Eye, 
  Download, 
  File, 
  ChevronRight
} from "lucide-react";
import { Document } from "@/types/document";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/shadcn-button";
import { Badge } from "@/components/ui/badge";
import fr from "@/constants/fr";
import { getDocumentIcon, getIconBackgroundColor } from "@/utils/documentIcons";

interface DocumentsTableProps {
  documents: Document[];
  isLoading: boolean;
  onViewDetails: (document: Document) => void;
  isMobile?: boolean;
}

const DocumentsTable: React.FC<DocumentsTableProps> = ({ 
  documents, 
  isLoading, 
  onViewDetails,
  isMobile = false 
}) => {
  const navigate = useNavigate();

  const formatDate = (dateValue: any) => {
    if (!dateValue) return "-";
    
    try {
      // Handle Firebase Timestamp objects which might have _seconds and _nanoseconds
      if (typeof dateValue === 'object' && dateValue !== null && '_seconds' in dateValue) {
        // Convert Firebase Timestamp to milliseconds and create a Date
        const milliseconds = dateValue._seconds * 1000;
        const date = new Date(milliseconds);
        if (isNaN(date.getTime())) {
          console.warn("Invalid date from Firebase Timestamp:", dateValue);
          return "-";
        }
        return new Intl.DateTimeFormat("fr-FR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).format(date);
      }
      
      // Handle standard date strings
      const date = new Date(dateValue);
      if (isNaN(date.getTime())) {
        console.warn("Invalid date detected:", dateValue);
        return "-";
      }
      
      return new Intl.DateTimeFormat("fr-FR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }).format(date);
    } catch (error) {
      console.error("Error formatting date:", error);
      return "-";
    }
  };

  const getDocTypeBadgeColor = (type: string) => {
    const typeColors: Record<string, string> = {
      'facture': 'bg-blue-100 text-blue-800',
      'cheque': 'bg-green-100 text-green-800', 
      'bon_de_commande': 'bg-purple-100 text-purple-800',
      'permis_de_construire': 'bg-yellow-100 text-yellow-800',
      'plan_architectural': 'bg-indigo-100 text-indigo-800',
      'default': 'bg-gray-100 text-gray-800'
    };
    
    return typeColors[type] || typeColors['default'];
  };

  if (isLoading) {
    return <div className="text-center py-4">{fr.common.loading}</div>;
  }

  if (documents.length === 0) {
    return (
      <div className="text-center py-8">
        <File className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-sm font-semibold text-gray-900">
          {fr.documents.noDocuments}
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          {fr.documents.noDocumentsDescription}
        </p>
      </div>
    );
  }

  // Mobile card view for documents
  if (isMobile) {
    return (
      <div className="space-y-3">
        {documents.map((document) => (
          <div 
            key={document.id} 
            className="border rounded-lg p-3 shadow-sm bg-white"
            onClick={() => onViewDetails(document)}
          >
            <div className="flex items-center gap-3">
              <div className={`flex h-10 w-10 shrink-0 items-center justify-center rounded-full ${getIconBackgroundColor(document.type, document.fileUrl)}`}>
                {getDocumentIcon(document.type, document.filePath)}
              </div>
              <div className="flex-1 min-w-0 overflow-hidden">
                <h3 className="font-medium text-sm truncate max-w-[200px]">
                  {document.name.length > 30 ? `${document.name.substring(0, 30)}...` : document.name}
                </h3>
                <div className="flex items-center gap-2 mt-1">
                  <Badge
                    variant="outline"
                    className={`text-xs px-1.5 py-0 ${getDocTypeBadgeColor(document.type)}`}
                  >
                    {document.type}
                  </Badge>
                  <span className="text-xs text-gray-500">{formatDate(document.createdAt)}</span>
                </div>
              </div>
              <ChevronRight className="h-4 w-4 text-gray-400" />
            </div>
            <div className="mt-2 flex justify-between items-center">
              <span className="text-xs text-gray-500">
                {document.projectName || "-"}
              </span>
              <div className="flex gap-1" onClick={(e) => e.stopPropagation()}>
                <Button variant="ghost" size="icon" className="h-8 w-8" asChild>
                  <a href={document.fileUrl} target="_blank" rel="noopener noreferrer">
                    <Eye className="h-3.5 w-3.5" />
                  </a>
                </Button>
                <Button variant="ghost" size="icon" className="h-8 w-8" asChild>
                  <a href={document.fileUrl} download>
                    <Download className="h-3.5 w-3.5" />
                  </a>
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  // Desktop table view
  return (
    <div className="overflow-hidden rounded-md border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-12"></TableHead>
            <TableHead>{fr.documents.name}</TableHead>
            <TableHead>{fr.documents.type}</TableHead>
            <TableHead>{fr.documents.project}</TableHead>
            <TableHead>{fr.documents.uploadDate}</TableHead>
            <TableHead className="text-right">{fr.common.actions}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {documents.map((document) => (
            <TableRow key={document.id} className="cursor-pointer" onClick={() => onViewDetails(document)}>
              <TableCell className="cursor-pointer">
                <div className={`flex h-10 w-10 items-center justify-center rounded-full ${getIconBackgroundColor(document.type, document.fileUrl)}`}>
                  {getDocumentIcon(document.type, document.filePath)}
                </div>
              </TableCell>
              <TableCell className="font-medium">
                {document.name}
              </TableCell>
              <TableCell>
                <Badge
                  variant="outline"
                  className={getDocTypeBadgeColor(document.type)}
                >
                  {document.type}
                </Badge>
              </TableCell>
              <TableCell>{document.projectName || "-"}</TableCell>
              <TableCell>{formatDate(document.createdAt)}</TableCell>
              <TableCell className="text-right" onClick={(e) => e.stopPropagation()}>
                <div className="flex justify-end gap-2">
                  <Button variant="outline" size="icon" asChild>
                    <a href={document.fileUrl} target="_blank" rel="noopener noreferrer">
                      <Eye className="h-4 w-4" />
                    </a>
                  </Button>
                  <Button variant="outline" size="icon" asChild>
                    <a href={document.fileUrl} download>
                      <Download className="h-4 w-4" />
                    </a>
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default DocumentsTable;
