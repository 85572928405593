
import React from "react";
import { Link } from "react-router-dom";
import Button from "@/components/ui/Button";
import { useLanguage } from "@/contexts/LanguageContext";

const NotFound: React.FC = () => {
  const { t, dir } = useLanguage();

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-background p-4 animate-fade-in" dir={dir}>
      <div className="text-center">
        <h1 className="text-9xl font-bold text-primary mb-4">404</h1>
        <h2 className="text-2xl font-medium mb-6">{t.errors.notFound}</h2>
        <Link to="/">
          <Button variant="primary">
            {t.common.returnHome}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
