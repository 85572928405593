import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "@/contexts/auth/AuthContext";
import api from "@/services/api";
import { Loader2 } from "lucide-react";

const AdminRoute = () => {
  const { currentUser, loading } = useAuth();
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
  const [checkingAdmin, setCheckingAdmin] = useState(true);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!currentUser) {
        setCheckingAdmin(false);
        return;
      }

      try {
        // Try to access an admin-only endpoint
        await api.get('/api/admin/check');
        setIsAdmin(true);
      } catch (error: any) {
        console.log('User is not an admin:', error.response?.status);
        setIsAdmin(false);
      } finally {
        setCheckingAdmin(false);
      }
    };

    checkAdminStatus();
  }, [currentUser]);

  if (loading || checkingAdmin) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
        <span className="ml-2 text-lg">Verifying admin access...</span>
      </div>
    );
  }

  // If not logged in, redirect to login
  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  // If logged in but not admin, redirect to unauthorized page
  if (!isAdmin) {
    return <Navigate to="/unauthorized" />;
  }

  // If admin, render the protected admin routes
  return <Outlet />;
};

export default AdminRoute; 