
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FolderKanban, Plus, Search } from "lucide-react";
import { getProjects, deleteProject } from "@/services/projectsService";
import { getClients } from "@/services/clientsService";
import { Button } from "@/components/ui/shadcn-button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import ProjectsTable from "@/components/projects/ProjectsTable";
import { toast } from "@/components/ui/use-toast";
import fr from "@/constants/fr";

const ProjectsPage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedClientId, setSelectedClientId] = useState<string>("");

  // Fetch projects data with optional client filter
  const { data: projects = [], isLoading: isLoadingProjects } = useQuery({
    queryKey: ["projects", selectedClientId],
    queryFn: () => getProjects(selectedClientId && selectedClientId !== "all" ? { clientId: selectedClientId } : undefined),
  });

  // Fetch clients for the filter dropdown
  const { data: clients = [] } = useQuery({
    queryKey: ["clients"],
    queryFn: () => getClients(),
  });

  // Delete project mutation
  const deleteMutation = useMutation({
    mutationFn: deleteProject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects"] });
      toast({
        title: fr.projects.projectDeleted,
      });
    },
    onError: (error) => {
      console.error("Error deleting project:", error);
      toast({
        title: fr.common.error,
        description: "Impossible de supprimer le projet",
        variant: "destructive",
      });
    },
  });

  const handleDelete = (id: string) => {
    deleteMutation.mutate(id);
  };

  // Filter projects by search term
  const filteredProjects = projects.filter(project => 
    project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (project.location && project.location.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  // Navigate to view documents for a project
  const handleViewDocuments = (projectId: string) => {
    navigate(`/admin/documents?projectId=${projectId}`);
  };

  return (
    <div className="space-y-6">
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
        <h1 className="text-3xl font-bold">{fr.projects.title}</h1>
        <Button onClick={() => navigate("/admin/projects/new")}>
          <Plus className="mr-2 h-4 w-4" />
          {fr.projects.addProject}
        </Button>
      </div>

      <Card>
        <CardHeader className="flex flex-col sm:flex-row items-start sm:items-center justify-between space-y-2 sm:space-y-0 pb-2">
          <CardTitle className="text-xl font-bold">
            <div className="flex items-center gap-2">
              <FolderKanban className="h-5 w-5" />
              Liste des projets
            </div>
          </CardTitle>
          <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
            <div className="relative w-full sm:w-64">
              <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                type="search"
                placeholder={fr.common.search}
                className="pl-8 w-full"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <Select
              value={selectedClientId}
              onValueChange={setSelectedClientId}
            >
              <SelectTrigger className="w-full sm:w-64">
                <SelectValue placeholder="Filtrer par client" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">Tous les clients</SelectItem>
                {clients.map((client) => (
                  <SelectItem key={client.id} value={client.id!}>
                    {client.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </CardHeader>
        <CardContent>
          <ProjectsTable
            projects={filteredProjects}
            clientNames={clients.reduce((acc, client) => ({ ...acc, [client.id!]: client.name }), {})}
            isLoading={isLoadingProjects}
            onDelete={handleDelete}
            onViewDocuments={handleViewDocuments}
          />
        </CardContent>
      </Card>
    </div>
  );
};

export default ProjectsPage;
