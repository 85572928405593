
import * as React from "react"
import { cn } from "@/lib/utils"
import { useIsMobile } from "@/hooks/use-mobile"
import { ChevronLeft, Menu } from "lucide-react"
import { Separator } from "@/components/ui/separator"
import { Button } from "@/components/ui/shadcn-button"
import { Sheet, SheetContent } from "@/components/ui/sheet"

// Create a context for the sidebar
type SidebarContextType = {
  isSidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
};

const SidebarContext = React.createContext<SidebarContextType | undefined>(undefined);

interface SidebarProviderProps {
  children: React.ReactNode
}

const SidebarProvider: React.FC<SidebarProviderProps> = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = React.useState(false);
  
  return (
    <SidebarContext.Provider value={{ isSidebarOpen, setSidebarOpen }}>
      {children}
    </SidebarContext.Provider>
  );
};

const useSidebar = () => {
  const context = React.useContext(SidebarContext);
  if (context === undefined) {
    throw new Error("useSidebar must be used within a SidebarProvider");
  }
  return context;
};

interface SidebarProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
}

const Sidebar = ({ children, className, ...props }: SidebarProps) => {
  const isMobile = useIsMobile();
  const { isSidebarOpen, setSidebarOpen } = useSidebar();

  if (isMobile) {
    return (
      <Sheet open={isSidebarOpen} onOpenChange={setSidebarOpen}>
        <SheetContent side="left" className="flex flex-col gap-2 border-r py-4 w-60 p-0" hideCloseButton>
          {children}
        </SheetContent>
      </Sheet>
    );
  }

  return (
    <aside className={cn("flex flex-col gap-2 border-r py-4 w-60", className)} {...props}>
      {children}
    </aside>
  );
};

const SidebarHeader = ({ className, children }: { className?: string; children: React.ReactNode }) => {
  return <div className={cn("px-4", className)}>{children}</div>
};

const SidebarFooter = ({ className, children }: { className?: string; children: React.ReactNode }) => {
  return <div className={cn("mt-auto px-4", className)}>{children}</div>
};

const SidebarContent = ({ className, children }: { className?: string; children: React.ReactNode }) => {
  return <div className={cn("flex-1", className)}>{children}</div>
};

interface SidebarTriggerProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
}

const SidebarTrigger: React.FC<SidebarTriggerProps> = ({ children, className, ...props }) => {
  const { isSidebarOpen, setSidebarOpen } = useSidebar();
  
  return (
    <Button 
      variant="ghost" 
      size="icon" 
      onClick={() => setSidebarOpen(!isSidebarOpen)}
      className={cn(className)}
      {...props}
    >
      {children || <Menu className="h-5 w-5" />}
    </Button>
  );
};

export {
  Sidebar,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  SidebarProvider,
  SidebarTrigger,
  useSidebar
}
