import React, { useState, useEffect } from "react";
import fr from "@/constants/fr";
import api from "@/services/api";
import { toast } from "@/hooks/use-toast";
import { Link } from "react-router-dom";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import Button from "@/components/ui/Button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Loader2, Users } from "lucide-react";

interface Settings {
  id: string;
  aiModel: string;
  botWhatsappNumber: string;
}

const SettingsPage: React.FC = () => {
  const [settings, setSettings] = useState<Settings | null>(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        setLoading(true);
        const response = await api.get('/api/admin/settings');
        setSettings(response.data);
        setError(null);
      } catch (err: any) {
        console.error('Error fetching settings:', err);
        setError(err.response?.data?.error || 'Failed to load settings');
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const handleSaveSettings = async () => {
    if (!settings) return;

    try {
      setSaving(true);
      await api.put(`/api/settings/${settings.id}`, {
        aiModel: settings.aiModel,
        botWhatsappNumber: settings.botWhatsappNumber
      });
      
      toast({
        title: "Settings saved",
        description: "Your settings have been updated successfully.",
      });
    } catch (err: any) {
      console.error('Error saving settings:', err);
      toast({
        variant: "destructive",
        title: "Failed to save settings",
        description: err.response?.data?.error || "An error occurred while saving settings.",
      });
    } finally {
      setSaving(false);
    }
  };

  const handleChange = (field: keyof Settings, value: string) => {
    if (settings) {
      setSettings({
        ...settings,
        [field]: value
      });
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 bg-destructive/10 text-destructive rounded-md">
        <p>{error}</p>
        <Button 
          variant="outline" 
          className="mt-2"
          onClick={() => window.location.reload()}
        >
          Retry
        </Button>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-2xl font-bold">{fr.navigation.settings}</h1>
        <p className="text-muted-foreground">
          Manage application settings and configurations.
        </p>
      </div>

      <Card className="mb-6">
        <CardHeader>
          <CardTitle>Administration</CardTitle>
          <CardDescription>
            Manage system administration settings.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Link to="/admin/settings/users">
            <Button variant="outline" className="w-full sm:w-auto flex items-center gap-2">
              <Users size={16} />
              Manage Users
            </Button>
          </Link>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>AI Model Settings</CardTitle>
          <CardDescription>
            Select which AI model to use for generating responses.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <RadioGroup
            value={settings?.aiModel || 'gemini-2.0-flash'}
            onValueChange={(value) => handleChange('aiModel', value)}
            className="space-y-4"
          >
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="gemini-2.0-flash" id="gemini" />
              <Label htmlFor="gemini">Gemini 2.0 Flash</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="gpt-4o" id="gpt4o" />
              <Label htmlFor="gpt4o">GPT-4o</Label>
            </div>
          </RadioGroup>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>WhatsApp Bot Settings</CardTitle>
          <CardDescription>
            Configure the WhatsApp bot number for client communication.
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="botNumber">Bot WhatsApp Number</Label>
            <Input
              id="botNumber"
              placeholder="e.g. 12345678901"
              value={settings?.botWhatsappNumber || ''}
              onChange={(e) => handleChange('botWhatsappNumber', e.target.value)}
            />
            <p className="text-sm text-muted-foreground">
              Enter the WhatsApp number including country code (e.g. 12345678901).
              <strong> Do not include any spaces, dashes, or the '+' symbol</strong> as WhatsApp's "Click to Chat" feature requires digits only.
              For example, a Moroccan number should be formatted as: 212XXXXXXXXX
            </p>
          </div>
        </CardContent>
      </Card>

      <Button 
        onClick={handleSaveSettings} 
        disabled={saving}
        className="w-full sm:w-auto"
      >
        {saving ? (
          <>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            Saving...
          </>
        ) : (
          'Save Settings'
        )}
      </Button>
    </div>
  );
};

export default SettingsPage;
