
const en = {
  common: {
    loading: "Loading...",
    error: "Error",
    save: "Save",
    cancel: "Cancel",
    edit: "Edit",
    delete: "Delete",
    search: "Search",
    actions: "Actions",
    required: "This field is required",
    appName: "WhatsappDocsWithAI",
    noResults: "No results found",
    filters: "Filters",
    applyFilters: "Apply filters",
    clearFilters: "Clear filters",
    returnHome: "Return home",
  },
  landing: {
    tagline: "WhatsApp Documents meets AI",
    description: "Manage all your documents easily. Send them via WhatsApp, let AI organize them for you.",
    startNow: "Start now",
    discoverFeatures: "Discover features",
    features: {
      title: "Key Features",
      subtitle: "Discover how our solution simplifies document management",
      docManagement: {
        title: "Smart Management",
        description: "Automatic organization of your documents by category, date, and content thanks to AI"
      },
      security: {
        title: "Advanced Security",
        description: "Protection of your documents with access control and secure sharing"
      },
      whatsapp: {
        title: "WhatsApp Integration",
        description: "Send your documents via WhatsApp for immediate classification and access"
      }
    },
    cta: {
      title: "Ready to simplify your document management?",
      description: "Join WhatsappDocsWithAI and access your documents anywhere, anytime",
      button: "Start now"
    }
  },
  auth: {
    login: "Log in",
    register: "Sign up",
    logout: "Log out",
    email: "Email address",
    password: "Password",
    confirmPassword: "Confirm password",
    invalidEmail: "Invalid email address",
    passwordMismatch: "Passwords do not match",
    registerSuccess: "Registration successful",
    loginSuccess: "Login successful",
    logoutSuccess: "Logout successful",
    loginError: "Login error",
    registerError: "Registration error",
    forgotPassword: "Forgot password?",
    resetPassword: "Reset password",
    resetPasswordSuccess: "Your password has been successfully reset.",
    resetPasswordError: "Error resetting password.",
    sendResetLink: "Send reset link",
    sendResetLinkSuccess: "A reset email has been sent.",
    sendResetLinkError: "Error sending reset email.",
    invalidCredentials: "Invalid credentials",
    emailInUse: "This email address is already in use",
    weakPassword: "Password is too weak",
    tooManyRequests: "Too many attempts, please try again later",
    networkError: "Network error, please check your connection",
    requiresRecentLogin: "This action requires a recent login",
    genericError: "An error has occurred",
  },
  clients: {
    title: "Clients",
    addClient: "Add client",
    editClient: "Edit client",
    deleteConfirm: "Are you sure you want to delete this client?",
  },
  companies: {
    title: "Companies",
    addCompany: "Add company",
    editCompany: "Edit company",
    name: "Name",
    client: "Client",
    ice: "ICE",
    taxId: "Tax ID",
    address: "Address",
    phone: "Phone",
    email: "Email",
    companyCreated: "Company created successfully",
    companyUpdated: "Company updated successfully",
    companyDeleted: "Company deleted successfully",
    deleteConfirm: "Are you sure you want to delete this company?",
  },
  projects: {
    title: "Projects",
    addProject: "Add project",
    editProject: "Edit project",
    name: "Name",
    client: "Client",
    company: "Company",
    description: "Description",
    location: "Address",
    status: "Status",
    startDate: "Start date",
    endDate: "End date",
    projectUpdated: "Project updated",
    projectDeleted: "Project deleted",
    projectCreated: "Project created successfully",
    statusActive: "Active",
    statusCompleted: "Completed",
    statusOnHold: "On hold",
    statusCancelled: "Cancelled",
    deleteConfirm: "Are you sure you want to delete this project?",
  },
  documents: {
    title: "Documents",
    list: "Document list",
    name: "Name",
    type: "Type",
    project: "Project",
    uploadDate: "Upload date",
    fileSize: "Size",
    noDocuments: "No documents",
    noDocumentsDescription: "No documents were found with the current filters.",
    upload: "Upload",
    view: "View",
    download: "Download",
    delete: "Delete",
    filterByProject: "Filter by project",
    filterByType: "Filter by type",
    allProjects: "All projects",
    allTypes: "All types",
    standardSearch: "Standard search",
    aiSearch: "AI search",
    aiSearchPlaceholder: "Ask AI to find documents...",
    aiSearchHelp: "Use AI to find documents based on content, keywords, or metadata",
    aiSearchExamples: "Try: \"Find all invoices for Project X\" or \"Show documents about electrical connections\"",
    resultsExplanation: "Results explanation:",
    searchComplete: "Search complete",
    searchFoundDocuments: "Found {count} document(s)",
    noResultsFound: "No results found",
    tryDifferentQuery: "Try a different search",
    details: "Document details",
    keywords: "Keywords",
    tags: "Tags",
    metadata: "Metadata",
    jsonContent: "JSON content",
    createdOn: "Created on",
    updatedOn: "Updated on",
    category: "Category",
    filters: "Filters",
    applyFilters: "Apply",
    clearFilters: "Clear all",
    preview: "Preview",
    previewNotAvailable: "Preview not available",
    cannotPreview: "This document cannot be previewed",
    fullscreen: "Fullscreen",
    closeFullscreen: "Close fullscreen",
    pdfLoading: "Loading PDF...",
    pdfError: "Unable to load PDF",
    openInNewTab: "Open in new tab",
    typeInvoice: "Invoice",
    typeCheck: "Check",
    typePurchaseOrder: "Purchase order",
    typeBuildingPermit: "Building permit",
    typeArchitecturalPlan: "Architectural plan",
    typeWaterConnection: "Water connection",
    typeElectricityConnection: "Electricity connection",
    typeUrbanAuthorization: "Urban authorization",
    typeComplianceCertificate: "Compliance certificate",
    typeQuote: "Quote",
    typeConstructionContract: "Construction contract",
    typeOther: "Other",
    downloadStarting: "Your download will start automatically",
    downloadManually: "If your download doesn't start automatically, click the button below",
    noProjectSpecified: "No project specified",
    provideProjectId: "Please provide a project ID in the URL",
    projectDocuments: "Project documents"
  },
  navigation: {
    dashboard: "Dashboard",
    clients: "Clients",
    companies: "Companies",
    projects: "Projects",
    documents: "Documents",
    settings: "Settings",
    whatsappTest: "WhatsApp Test",
    home: "Home"
  },
  dashboard: {
    title: "Dashboard",
    summary: "Summary",
    welcome: "Welcome to your dashboard",
    totalClients: "Total clients",
    totalCompanies: "Total companies",
    totalProjects: "Total projects",
    totalDocuments: "Total documents",
    recentProjects: "Recent projects",
    recentDocuments: "Recent documents",
    documentsCount: "Documents",
    projectsCount: "Projects",
    companiesCount: "Companies",
    clientsCount: "Clients"
  },
  whatsapp: {
    title: "WhatsApp Test",
  },
  errors: {
    notFound: "Page not found",
    unauthorized: "Unauthorized",
    serverError: "Server error",
    unknownError: "An unknown error occurred",
  },
  language: {
    title: "Language",
    fr: "French",
    en: "English",
    ar: "Arabic"
  }
};

export default en;
