import React from 'react';
import { MessageCircle } from 'lucide-react';
import { generateWhatsAppLink } from '@/utils/whatsapp';

interface WhatsAppButtonProps {
  phoneNumber: string;
  message?: string;
  buttonText?: string;
  className?: string;
  variant?: 'default' | 'outline' | 'icon';
  size?: 'sm' | 'md' | 'lg';
  position?: 'fixed' | 'static';
}

/**
 * A reusable WhatsApp button component
 */
const WhatsAppButton: React.FC<WhatsAppButtonProps> = ({
  phoneNumber,
  message,
  buttonText = 'Chat on WhatsApp',
  className = '',
  variant = 'default',
  size = 'md',
  position = 'static'
}) => {
  if (!phoneNumber) return null;
  
  const whatsappLink = generateWhatsAppLink(phoneNumber, message);
  
  // Base styles
  let buttonStyles = 'inline-flex items-center gap-2 rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2';
  
  // Variant styles
  if (variant === 'default') {
    buttonStyles += ' bg-green-600 hover:bg-green-700 text-white';
  } else if (variant === 'outline') {
    buttonStyles += ' border border-green-600 text-green-600 hover:bg-green-50';
  } else if (variant === 'icon') {
    buttonStyles += ' bg-green-600 hover:bg-green-700 text-white p-2 rounded-full';
  }
  
  // Size styles
  if (size === 'sm') {
    buttonStyles += variant === 'icon' ? ' p-1.5' : ' px-3 py-1.5 text-sm';
  } else if (size === 'md') {
    buttonStyles += variant === 'icon' ? ' p-2' : ' px-4 py-2';
  } else if (size === 'lg') {
    buttonStyles += variant === 'icon' ? ' p-3' : ' px-6 py-3 text-lg';
  }
  
  // Position styles
  if (position === 'fixed') {
    buttonStyles += ' fixed bottom-6 right-6 z-50 shadow-lg';
  }
  
  return (
    <a
      href={whatsappLink}
      target="_blank"
      rel="noopener noreferrer"
      className={`${buttonStyles} ${className}`}
      aria-label="Chat on WhatsApp"
    >
      <MessageCircle size={variant === 'icon' ? 24 : size === 'sm' ? 16 : size === 'md' ? 18 : 24} />
      {variant !== 'icon' && <span>{buttonText}</span>}
    </a>
  );
};

export default WhatsAppButton; 