
import React from "react";
import { Link } from "react-router-dom";
import { FileText, ChevronRight } from "lucide-react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/shadcn-button";

interface Document {
  id: string;
  name: string;
  type: string;
  project: string;
}

interface RecentDocumentsProps {
  title: string;
  documents: Document[];
}

const RecentDocuments: React.FC<RecentDocumentsProps> = ({ title, documents }) => {
  return (
    <Card className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">{title}</h2>
        <Button variant="ghost" size="sm" asChild>
          <Link to="/admin/documents" className="flex items-center gap-1">
            Voir tout <ChevronRight className="h-4 w-4" />
          </Link>
        </Button>
      </div>
      <div className="space-y-3">
        {documents.map((document) => (
          <Link 
            key={document.id}
            to={`/admin/documents/${document.id}`}
            className="flex items-center p-3 rounded-lg hover:bg-secondary transition-colors"
          >
            <div className="p-2 mr-3 rounded bg-primary/10">
              <FileText className="h-5 w-5 text-primary" />
            </div>
            <div className="flex-1">
              <h3 className="font-medium">{document.name}</h3>
              <p className="text-sm text-muted-foreground">{document.project}</p>
            </div>
          </Link>
        ))}
      </div>
    </Card>
  );
};

export default RecentDocuments;
