
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FileText, Download, AlertCircle } from 'lucide-react';
import { Button } from '@/components/ui/shadcn-button';
import { Skeleton } from '@/components/ui/skeleton';
import { getShareDownloadUrl } from '@/services/documentsService';
import fr from '@/constants/fr';
import api from '@/services/api';

export default function DocumentDownload() {
  const { shareId } = useParams<{ shareId: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [documentName, setDocumentName] = useState<string | null>(null);

  useEffect(() => {
    const initiateDownload = async () => {
      if (!shareId) {
        setError('Document ID is missing');
        setLoading(false);
        return;
      }

      try {
        // First try to get basic document info
        // This may fail if the API doesn't support this endpoint, but we'll continue with the download anyway
        try {
          const response = await api.get(`/api/documents/share/${shareId}`);
          if (response.data) {
            setDocumentName(response.data.name || 'Document');
          }
        } catch (infoError) {
          console.log('Could not fetch document info, continuing with download');
          setDocumentName('Document');
        }

        // Then redirect to the download URL
        const downloadUrl = getShareDownloadUrl(shareId);
        
        // Set a timeout to allow the page to render before redirecting
        setTimeout(() => {
          window.location.href = downloadUrl;
          setLoading(false);
        }, 1500);
      } catch (err: any) {
        console.error('Error initiating document download:', err);
        setError(err.message || 'Failed to download document. Please try again later.');
        setLoading(false);
      }
    };

    initiateDownload();
  }, [shareId, navigate]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-background p-4">
        <div className="w-full max-w-md text-center">
          <Skeleton className="h-16 w-16 rounded-full mx-auto mb-4" />
          <Skeleton className="h-8 w-3/4 mx-auto mb-4" />
          <Skeleton className="h-4 w-1/2 mx-auto mb-2" />
          <Skeleton className="h-4 w-1/3 mx-auto" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-background p-4">
        <div className="glass-card w-full max-w-md p-8 text-center">
          <div className="text-destructive mb-4">
            <AlertCircle className="h-16 w-16 mx-auto" />
          </div>
          <h1 className="text-xl font-bold mb-2">{fr.errors.notFound}</h1>
          <p className="text-muted-foreground mb-6">{error}</p>
          <Button onClick={() => navigate('/')}>
            {fr.common.returnHome}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-background p-4">
      <div className="glass-card w-full max-w-md p-8">
        <div className="flex flex-col items-center text-center">
          <div className="p-3 rounded-full bg-primary/10 mb-4">
            <FileText className="h-12 w-12 text-primary" />
          </div>
          <h1 className="text-xl font-bold mb-2">
            {documentName || 'Document'}
          </h1>
          <p className="text-muted-foreground mb-6">
            {fr.documents.downloadStarting}
          </p>
          
          <a
            href={getShareDownloadUrl(shareId || '')}
            className="w-full"
          >
            <Button className="w-full" size="lg">
              <Download className="mr-2 h-4 w-4" />
              {fr.documents.download}
            </Button>
          </a>
        </div>
      </div>
      
      <div className="mt-6 text-center text-sm text-muted-foreground">
        <p>Shared via {fr.common.appName}</p>
      </div>
    </div>
  );
}
