
import api from "./api";
import { Company, CompanyFormData } from "@/types/company";

// Get all companies with optional client filter
export const getCompanies = async (clientId?: string) => {
  const params = clientId ? { clientId } : {};
  const response = await api.get("/api/companies", { params });
  return response.data;
};

// Get a single company by ID
export const getCompany = async (id: string): Promise<Company> => {
  const response = await api.get(`/api/companies/${id}`);
  return response.data;
};

// Create a new company
export const createCompany = async (data: CompanyFormData): Promise<Company> => {
  const response = await api.post("/api/companies", data);
  return response.data;
};

// Update an existing company
export const updateCompany = async (id: string, data: CompanyFormData): Promise<Company> => {
  const response = await api.put(`/api/companies/${id}`, data);
  return response.data;
};

// Delete a company
export const deleteCompany = async (id: string): Promise<void> => {
  await api.delete(`/api/companies/${id}`);
};
