import React, { useState } from 'react';
import { useLanguage, Language } from '@/contexts/LanguageContext';
import { Globe, ChevronDown } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/shadcn-button';
import { cn } from '@/lib/utils';

interface LanguageSelectorProps {
  variant?: 'header' | 'sidebar' | 'minimal';
  className?: string;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ 
  variant = 'header', 
  className = '' 
}) => {
  const { language, setLanguage, t } = useLanguage();
  const [open, setOpen] = useState(false);

  const languageOptions: { value: Language; label: string; icon?: string }[] = [
    { value: 'fr', label: t.language.fr },
    { value: 'en', label: t.language.en },
    { value: 'ar', label: t.language.ar },
  ];

  const handleSelect = (lang: Language) => {
    setLanguage(lang);
    setOpen(false);
  };

  const getLanguageDisplay = (lang: Language) => {
    switch (lang) {
      case 'fr': return 'FR';
      case 'en': return 'EN';
      case 'ar': return 'عر';
      default: return lang; // Handle the default case without toUpperCase
    }
  };

  if (variant === 'minimal') {
    
    return (
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <DropdownMenuTrigger asChild>
          <Button 
            variant="ghost" 
            size="icon" 
            className={cn("hover:bg-primary/10", className)}
          >
            <Globe className="h-[1.2rem] w-[1.2rem]" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-40 bg-popover">
          {languageOptions.map((option) => (
            <DropdownMenuItem
              key={option.value}
              onClick={() => handleSelect(option.value)}
              className={cn(
                "cursor-pointer",
                language === option.value && "bg-primary/10 font-medium"
              )}
            >
              {option.label}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Button 
          variant={variant === 'header' ? "ghost" : "outline"} 
          size={variant === 'header' ? "sm" : "default"}
          className={cn(
            "flex items-center gap-1 px-2", 
            variant === 'sidebar' && "w-full justify-start",
            className
          )}
        >
          <Globe className="h-4 w-4 mr-1" />
          <span>{getLanguageDisplay(language)}</span>
          <ChevronDown className="h-3 w-3 opacity-50" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-40 bg-popover">
        {languageOptions.map((option) => (
          <DropdownMenuItem
            key={option.value}
            onClick={() => handleSelect(option.value)}
            className={cn(
              "cursor-pointer",
              language === option.value && "bg-primary/10 font-medium"
            )}
          >
            {option.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default LanguageSelector;
