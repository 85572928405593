
import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import fr from '@/constants/fr';
import en from '@/constants/en';
import ar from '@/constants/ar';

// Define the available languages
export type Language = 'fr' | 'en' | 'ar';

// Create a type for our translations
export type Translations = typeof fr;

// Create a type for our context
interface LanguageContextType {
  language: Language;
  setLanguage: (lang: Language) => void;
  t: Translations;
  dir: 'ltr' | 'rtl';
}

// Create the context with a default value
const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

// Language data map
const languages: Record<Language, { translations: Translations, dir: 'ltr' | 'rtl' }> = {
  fr: { translations: fr, dir: 'ltr' },
  en: { translations: en, dir: 'ltr' },
  ar: { translations: ar, dir: 'rtl' },
};

// Create a provider component
interface LanguageProviderProps {
  children: ReactNode;
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
  // Initialize with French as the default language
  const [language, setLanguageState] = useState<Language>('fr');
  
  // Get the translations and direction for the current language
  const { translations, dir } = languages[language];

  // Effect to load saved language preference from localStorage
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') as Language;
    if (savedLanguage && languages[savedLanguage]) {
      setLanguageState(savedLanguage);
    }
  }, []);

  // Effect to set document direction based on language
  useEffect(() => {
    document.documentElement.dir = dir;
    document.documentElement.lang = language;
  }, [language, dir]);

  // Function to change the language
  const setLanguage = (lang: Language) => {
    setLanguageState(lang);
    localStorage.setItem('language', lang);
  };

  // Context value
  const value = {
    language,
    setLanguage,
    t: translations,
    dir,
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

// Custom hook to use the language context
export const useLanguage = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
